.c-swiper {
  position: relative;
  overflow: visible;

  @include breakpoint-pc {
//    overflow: hidden;
  }
  
  // index.php(トップページ)で使用するLineupのスライドショー
  &.-frontlineup {
    .swiper-slide {
      &.swiper-slide-next {
        .c-product_item.-slide {
          .c-product_inner {
            //ブラウザ幅が1000px以上の時のみ、nextスライドショーアイテムの右側枠線の色を透明にする。borderの存在自体は消したくない(幅が変わるため)ので、色を透明にすることで対応する
            @include breakpoint-narrowinner {
              border-right-color: transparent;
            }
          }
        }
      }
    }
  }
  
  // single-products.phpで使用する画像のスライドショー
  &.-product {
    .swiper-slide {
      img {
        width: 100%;
      }
    }
  }


  &_wrapper {
    @include breakpoint-max {
    }
  }

  &_slide {
  }
  
  .swiper-button-prev, .swiper-button-next {
    background-image: none;
  }
  
  ///// prev、pagination、nextを入れるナビゲーションボックス /////
  &_nav {
    &_prev, &_next {
      position: absolute;
      top: 50%;
      z-index: 2;
      color: color-wh(1);
      mix-blend-mode: exclusion;  //除外
    }
    &_prev {
      left: 10px;
      transform: translate(0, -50%);
    }
    &_next {
      right: 10px;
      transform: translate(0, -50%);
    }
  }
  
  ///// bullet /////
  &_pagination {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    position: relative !important;
    bottom: 0 !important;
    width: auto !important;
    margin: 20px auto 0;
    gap: 0 8px;
    
    &.swiper-pagination-bullets {
      .swiper-pagination-bullet {
        position: relative;
        z-index: 0;
        width: 10px;
        height: 10px;
        background-color: color-wh(1);
        opacity: 1 !important;
        
        &.swiper-pagination-bullet-active {
          &:after {
            background-color: color-blue01(1);
          }
        }
        
        &:after {
          display: inline;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          box-sizing: border-box;
          background-color: color-blue01(0.39);
        }
      }
    }
  }
}