////// text settings //////
@mixin fontSize($pc, $sp) {
  font-size: $sp + rem;

  @include breakpoint-pc {
    font-size: $pc + rem;
  }
}

@mixin lineHeight($pc, $sp) {
  line-height: $sp;
  @include breakpoint-pc {
    line-height: $pc;
  }
}

@mixin align($status) {
  text-align: left !important;
  @if $status == 'l' {
    @include breakpoint-pc {
      text-align: left !important;
    }
  } @else if $status == 'r' {
    @include breakpoint-pc {
      text-align: right !important;
    }
  } @else if $status == 'c' {
    @include breakpoint-pc {
      text-align: center !important;
    }
  }
}
.align-l {
  @include align("l");
}
.align-r {
  @include align("r");
}
.align-c {
  @include align("c");
}

.br, %br {
  display: none;
  @include breakpoint-pc {
    display: inline-block;
  }
}

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/


/* 句読点の文字間のみ詰める */
.punctuation-half-ls {
  letter-spacing: -0.5em;
}


////// list //////
@mixin listIndent($font-pc, $font-sp) {
  & li {
    text-indent: - $font-sp + rem;
    padding-left: $font-sp + rem;
    @include breakpoint-pc {
      text-indent: - $font-pc + rem;
      padding-left: $font-pc + rem;
    }
  }
}


////// table scroll //////
//usage:tableの一階層上に追加
@mixin tableScroll($tableW) {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  // scroll bar
  &:-webkit-scrollbar{
   height: 8px;
  }
  &::-webkit-scrollbar-track{
   background: #333;
  }
  &::-webkit-scrollbar-thumb {
   background: #999;
  }
  // table設定
  & table {
    width: $tableW + px;
  }
}


////// img cleary (transform) //////
@mixin imgCleary() {
  backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

////// tategaki //////
@mixin tategaki() {
  writing-mode: vertical-rl;
  text-align: left;
  text-orientation: upright; // 正立/横倒し mixed / upright / sideways
  //text-combine-upright: all; // 縦中横 all / digits 2~4
  //-ms-text-combine-horizontal: : digits 2;
  & .combine {
    text-combine-upright: all;
  }
}

//解除
@mixin tategaki-off() {
  writing-mode: horizontal-tb;
  text-align: inherit;
  text-orientation: inherit; // 正立/横倒し mixed / upright / sideways
  //text-combine-upright: all; // 縦中横 all / digits 2~4
  //-ms-text-combine-horizontal: : digits 2;
   & .combine {
     text-combine-upright: none;
   }
}

////// mouse over setings //////
.hover, %hover {
  filter:alpha(opacity=100);
  opacity:1;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    filter:alpha(opacity=70);
    opacity:0.7;
  }
}

////// anchor //////
.anchor, %anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block;
  
  @include breakpoint-pc {
    top: -72px;
  }
  @include breakpoint-content {
    top: -80px;
  }
}

////// clearfix //////
@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

////// ... //////
//@include abridgement(行間,文字数,表示したい行数,#背景色);
@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}


//1行以下略
@mixin ellipsisOneLine() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


//指定行以下略
@mixin ellipsisSetLine($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}


// inner box padding setting
@mixin innerBox() {
  padding-left: 1vw;
  padding-right: 1vw;
  @include breakpoint-maxinnerhalf {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  @media (min-width:900px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}


@mixin innerBox2() {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  @include breakpoint-maxinnerhalf {
    padding-left: 0.75vw;
    padding-right: 0.75vw;
  }
  @media (min-width:900px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}


// Sweepボタン関連
// Gradient mixin for Sweep button
/*
@mixin sweepButtonGradient(){
  background: color-black01(1);
  background: -moz-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-red02(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, color-black01(1)), color-stop(94%, color-red02(1)), color-stop(100%, color-red02(1)));
  background: -webkit-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-red02(1) 100%);
  background: -o-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-red02(1) 100%);
  background: -ms-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-red02(1) 100%);
  background: linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-red02(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#182632', endColorstr='#182632',GradientType=1 );
}
*/
@mixin sweepButtonGradient(){
  background: color-black01(1);
  background: -moz-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-black01(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, color-black01(1)), color-stop(94%, color-red02(1)), color-stop(100%, color-black01(1)));
  background: -webkit-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-black01(1) 100%);
  background: -o-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-black01(1) 100%);
  background: -ms-linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-black01(1) 100%);
  background: linear-gradient(90deg, color-black01(1) 0%, color-red02(1) 94%, color-black01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#182632', endColorstr='#182632',GradientType=1 );
}


// Transform mixin
@mixin transform($property){	
	-webkit-transform: $property;
  -moz-transform: $property;
  transform: $property; 
}

// Transition mixin
@mixin single-transition($property:all, $speed:150ms, $ease:ease, $delay: 0s){	
	-webkit-transition: $property $speed $ease $delay;	
  transition: $property $speed $ease $delay;
}
