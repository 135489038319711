@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function color-white01($opacity) {
  @return rgba(247, 247, 247, $opacity);  //#f7f7f7、基本背景色
}
@function color-white02($opacity) {
  @return rgba(232, 237, 242, $opacity);  //#e8edf2、灰色気味の白背景色
}

@function color-black01($opacity) {
  @return rgba(24, 38, 50, $opacity);  //#182632、見出しテキスト色
}

@function color-gray01($opacity) {
  @return rgba(102, 102, 102, $opacity);  //#666666、テキスト色、ボタン枠線色
}
@function color-gray02($opacity) {
  @return rgba(194, 194, 194, $opacity);  //#c2c2c2、Moreなどの背景色、区切り線色
}
@function color-gray03($opacity) {
  @return rgba(217, 225, 228, $opacity);  //#d6e1e4、AboutのKVの上端背景色
}

@function color-red01($opacity) {
  @return rgba(195, 13, 35, $opacity);  //#c30d23、強調テキスト色
}
@function color-red02($opacity) {
  @return rgba(198, 43, 58, $opacity);  //#c62b3a、Moreなどのホバー背景色
}
@function color-red03($opacity) {
  @return rgba(123, 39, 49, $opacity);  //#7b2731、ボタン枠線1(暗い色)
}
@function color-red04($opacity) {
  @return rgba(239, 1, 30, $opacity);  //#ef011e、ボタン枠線2(明るい色)
}

@function color-blue01($opacity) {
  @return rgba(19, 60, 95, $opacity);  //#133c5f、青
}
@function color-blue02($opacity) {
  @return rgba(191, 216, 237, $opacity);  //#bfd8ed、選択色
}

@function color-green01($opacity) {
  @return rgba(210, 214, 201, $opacity);  //#d2d6c9、トップページKVのPC時背景色
}




// Gradient mixin for Sweep button
@mixin sweepButtonGradient(){
    background: rgb(0,0,0);
  background: -moz-linear-gradient(90deg,  color-brown01(1) 0%, color-brown02(1) 94%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,color-brown01(1)), color-stop(94%,color-brown02(1)), color-stop(100%,color-brown01(1)));
  background: -webkit-linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  background: -o-linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  background: linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b664e', endColorstr='#8b664e',GradientType=1 );
}
/* 2022/12/09変更前
@mixin sweepButtonGradient(){
    background: rgb(0,0,0);
  background: -moz-linear-gradient(90deg,  color-brown01(1) 0%, color-blue01(1) 94%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,color-brown01(1)), color-stop(94%,color-blue01(1)), color-stop(100%,color-brown01(1)));
  background: -webkit-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: -o-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004656', endColorstr='#004656',GradientType=1 );
}
*/


/*
// Gradient mixin for Rise button
@mixin riseButtonGradient(){
  background: color-brown01(1);
  background: -moz-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left, right, color-stop(0%, color-brown01(1)), color-stop(25%, rgba(53,50,48,1)), color-stop(43.5%, color-blue01(1)), color-stop(50%, color-blue01(1)), color-stop(56.5%, color-blue01(1)), color-stop(75%, rgba(53,50,48,1)), color-stop(100%, color-brown01(1)),);
  background: -webkit-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -o-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%,  color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b664e', endColorstr='#004656',GradientType=1 );
}
*/
