$modal-padding-x-pc: 60px;
$modal-padding-y-pc: 50px;

$modal-padding-x-sp: 5%;
$modal-padding-y-sp: 40px;

.c-modal {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
//  width: calc(100% - #{$modal-padding-x-pc});
//  height: calc(100% - #{$modal-padding-y-pc});
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  background: transparent !important;
  pointer-events: none;

  @include breakpoint-tb {
  }
  @include breakpoint-pc {
  }
  
  &.remodal {
    padding: 0;
    pointer-events: none;

    @include breakpoint-pc {
      padding: 0;
    }
  }
  
  &.-img {
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-center;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }

  
  //クリック・タップオープン対象
  &_open {
    position: relative;
  }

  //画像拡大モーダルウィンドウ
  &.-largeimg {    
  }
  
  //閉じるボタン
  .remodal-close {
    position: absolute;
    top: -5px;
    left: auto;
    right: 0;
    transform: translateY(-100%);
    width: 20px;
    height: 17px;
    background-image: url("../images/common/icon/modal_close.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    @include breakpoint-pc {
      width: 20px;
      height: 17px;
    }

    &:before {
      display: none;
    }

    &:focus {
      outline:0;
    }
  }
  
  &_inner {
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    
    img {
      position: relative;
      z-index: 1;
      width: auto;
      height: auto;
//      width: 100%;
//      height: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: scale-down;
      pointer-events: auto;
    }
  }
}


//モーダルオープン時の、モーダルの上下左右余白
.remodal-wrapper {
  @include flex-wrap-wrap;
  @include flex-justify-center;
  @include flex-align-items-center;
  @include flex-align-content-center;
  padding: $modal-padding-y-sp $modal-padding-x-sp !important;
  box-sizing: border-box !important;
  
  @include breakpoint-pc {
    padding: $modal-padding-y-pc $modal-padding-x-pc !important;
  }
  
  &::after {
    position: absolute;
    top: 0;
    left: 0;
//    display: none !important;
  }
  
  &.remodal-is-opening, &.remodal-is-opened, &.remodal-is-closing {
    display: flex !important;
  }
}

//モーダルオープン時の、モーダル以外の全面背景色設定
.remodal-overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

//モーダルウィンドウの最大幅を指定しない
.remodal {
  max-width: none !important;
  

  @include breakpoint-pc {
    max-width: 1200px !important;
  }
}