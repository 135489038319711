.c-flex {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  
  &_item {
    &.-box2 {
      width: 100%;

      @include breakpoint-pc {
        width: 47.5%;
      }
    }
  }
}