body {
  width: 100%;
  @include font-ntsan("r");
  @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
  line-height: 1;
  color: color-gray01(1);
  background: color-white01(1);

  @include breakpoint-pc {
  }
}

a {
  color: inherit;
  text-decoration: none;
}

::selection {
  background: color-blue02(1);
}
::-moz-selection {
  background: color-blue02(1);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}
