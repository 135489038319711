.p1-logo {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: 100%;
  height: 0;
  box-sizing: border-box;

  @include breakpoint-sp {
  }
  @include breakpoint-pc {
  }
  @include breakpoint-content {
  }

  &_inner {
    width: 100%;
    height: 0;
    max-width: $max;  //ヘッダーナビの中身の最大横幅
    margin: 0 auto;
  }

  &_content {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding: 0 5%;
    box-sizing: border-box;
  }

  &_item {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    width: 220px;
    height: $drawer-space-sp;

    @include breakpoint-sp {
      width: 240px;
    }
    @include breakpoint-pc {
      width: 220px;
      height: $drawer-space-pc;
    }
    @include breakpoint-narrowinner {
      width: 280px;
    }
  }

  &_img {
    display: block;
    width: 100%;
    height: auto;
    margin-right: 12px;

    @include breakpoint-sp {
      margin-right: 14px;
    }
    @include breakpoint-pc {
      margin-right: 10px;
    }
    @include breakpoint-content {
      margin-right: 18px;
    }
  }
}