.p3-shadow {
  ///// テキストシャドウ /////
  &.-shadow01 {
    -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
  }

  &.-shadow02 {
    -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
  }
}