.p3-box {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;

  &_img {
    position: relative;
    width: 50%;

    &Inner {
      position: relative;
      
      &.-visible {
        overflow: visible;
      }
      
      &.-hidden {
        overflow: hidden;
      }
      
      img {
        position: relative;
        z-index: 2;
      }
    }

    ///// 画像＆画像周辺装飾 /////
    &_deco {
      position: absolute !important;
  
      ///// 当クリニックについて(院長挨拶 - 資格・所属学会) /////
      &.-about2 {
        top: 41.3333333333333vw;  //310px / 750px
        right: -20vw;  //-150px / 750px
        z-index: 0;
        width: 164.8vw;  //1236px / 750px
  
        @include breakpoint-pc {
          top: auto;
          bottom: -19vw;  //190px / 1000px
          right: auto;
          left: 43vw;  //430px / 1000px
          width: 71.6vw;  //716px / 1000px
        }
        @include breakpoint-content {
          bottom: -190px;
          left: 430px;
          width: 716px;
        }
      }
    }
  }

  &_info {
    position: relative;
    width: 50%;
  }

  &.-aboutbox01 {
    @include flex-align-items-end;
    position: relative;
    box-sizing: border-box;

    @include breakpoint-pc {
      padding-bottom: 140px;
    }
    
    .p3-box_img {
      width: 84vw;  //630px / 750px
      margin-bottom: 10px;

      @include breakpoint-pc {
        width: 59.5%;  //560px / 940px
        margin-bottom: 0;
      }
      
      &Inner {
        
      }
    }
    
    .p3-box_info {
      width: 100%;
      transform: translateY(0.7em);  //(line-height: 2.4;なので、画像と下端を揃えるために、(2.4 - 1) / 2 = 0.7 の分(行の高さから文字の高さを引いた分の半分(文字の下の分))だけ下に下げる)

      @include breakpoint-pc {
        width: 34%;  //320px / 940px
      }
    }
    
    
  }


  &.-onlinebox01 {
    position: relative;
    padding: 20px;
    border: 2px solid color-sub10(1);
    border-radius: 25px;
    box-sizing: border-box;
    
    @include breakpoint-pc {
      padding: 40px;
    }
    
  }


}