.p3-text {  
  ///// 見出しテキスト寄せ /////
  &.-left {
    text-align: left;
  }  
  &.-center {
    text-align: center;
  }  
  &.-right {
    text-align: right;
  }  
  &.-justify {
    text-align: justify;
  }
  
  ///// 見出しテキストカラー /////
  &.-wh {
    color: color-wh(1);
  }
  &.-bk {
    color: color-bk(1);
  }
  &.-white01 {
    color: color-white01(1);
  }
  &.-black01 {
    color: color-black01(1);
  }
  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-red01 {
    color: color-red01(1);
  }
  &.-red02 {
    color: color-red02(1);
  }

  
  ///// WYSIWYGスタイル /////
  //見出し(中)
  &.-articlettl01 {
    margin-bottom: 20px;
    @include font-zomin("bd");
    @include fontSize(3.6, 2.88);  //3.6 * 0.8 = 2.88
    line-height: 1.3;
    color: color-black01(1);

    @include breakpoint-pc {
      margin-bottom: 40px;
    }
  }
  //見出し(小)
  &.-articlettl02 {
    margin-bottom: 10px;
    @include font-zomin("bd");
    @include fontSize(2.6, 2.08);  //2.6 * 0.8 = 2.08
    line-height: 1.3;
    color: color-black01(1);

    @include breakpoint-pc {
      margin-bottom: 20px;
    }
  }
  //通常テキスト(Regular、17px)
  &.-articletxt01 {
    @include font-ntsan("r");
    @include fontSize(1.7, 1.36); //1.7 * 0.8 = 1.36
    text-align: justify;
    line-height: 1.8;
    color: color-gray01(1);
  }
  //通常テキスト(Medium、15px)
  &.-articletxt02 {
    @include font-ntsan("m");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    text-align: justify;
    letter-spacing: .09rem;
    line-height: 1.85;
    color: color-gray01(1);
  }
  //製品Spec・Type見出し
  &.-productspecttl01 {
    @include font-ntsan("m");
    @include fontSize(2.2, 1.76); //1.5 * 0.8 = 1.76
    line-height: 1.8;
    color: color-black01(1);
  }
  //製品Spec・Typeテキスト
  &.-productspectxt01 {
    @include font-ntsan("r");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    text-align: justify;
    letter-spacing: .09rem;
    line-height: 1.85;
    color: color-gray01(1);
  }


  ///// テキスト /////
  &.-t01 {
    @include font-ntsan("r");
    @include fontSize(1.7, 1.36); //1.7 * 0.8 = 1.36
    line-height: 1.8;

    @include breakpoint-pc {
    }
    
    &.-lh185 {
      line-height: 1.85;
    }
    &.-lsr010 {
      letter-spacing: .1rem;
    }
  }

  &.-t02 {
    @include font-ntsan("r");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    line-height: 1.8;

    &.-lsr003 {
      letter-spacing: .03rem;
    }
    &.-lsr004 {
      letter-spacing: .04rem;
    }
    &.-privacy {
      line-height: 1.85;
      letter-spacing: .03rem;
    }

    @include breakpoint-pc {
    }
  }

  &.-t03 {
    @include font-ntsan("m");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    line-height: 1.8;

    @include breakpoint-pc {
    }
    
    &.-front {
      line-height: 1.85;
      letter-spacing: .09rem;
    }
    &.-contact {
      line-height: 1.85;
      letter-spacing: .04rem;
    }
    &.-privacy {
      line-height: 1.85;
      letter-spacing: .03rem;
    }
  }

  &.-t04 {
    @include font-oswald("m");
    @include fontSize(2.8, 2.24); //2.8 * 0.8 = 2.24
    line-height: 1.5;

    @include breakpoint-pc {
    }
  }

  &.-t05 {
    @include font-zomin("r");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    line-height: 1.5;

    @include breakpoint-pc {
    }
  }

  &.-t06 {
    @include font-alibsb("r");
    @include fontSize(1.8, 1.44); //1.8 * 0.8 = 1.44
    line-height: 1.5;
    
    &.-lh100 {
      line-height: 1;
    }

    @include breakpoint-pc {
    }
  }

  &.-t07 {
    @include font-critxt("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.2
    line-height: 1.5;

    @include breakpoint-pc {
    }
  }

  &.-t08 {
    @include font-alibsb("r");
    font-size: 3.36rem; //4.8 * 0.7 = 3.36
    line-height: 1.8;

    @include breakpoint-tb {
      @include fontSize(4.8, 3.84); //4.8 * 0.8 = 1.2
    }
    @include breakpoint-pc {
    }
    
    &.-ls02 {
      letter-spacing: 0.025em;

      @include breakpoint-sp {
        letter-spacing: 0.05em;
      }
      @include breakpoint-tb {
        letter-spacing: 0.1em;
      }
      @include breakpoint-pc {
        letter-spacing: 0.15em;
      }
      @include breakpoint-narrow {
        letter-spacing: 0.2em;
      }
    }
  }

  &.-t09 {
    @include font-oswald("r");
    @include fontSize(1.3, 1.04); //1.3 * 0.8 = 1.04
    line-height: 1.5;

    @include breakpoint-pc {
    }
  }

  &.-t10 {
    @include font-critxt("ri");
    @include fontSize(3.8, 3.04); //3.8 * 0.8 = 3.04
    line-height: 1.5;

    @include breakpoint-pc {
    }
    
    &.-lh100 {
      line-height: 1;
    }
  }

  &.-t11 {
    @include font-oswald("m");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    line-height: 1.5;

    @include breakpoint-pc {
    }
    
    &.-lh100 {
      line-height: 1;
    }
  }

  &.-t12 {
    @include font-ntsan("bd");
    @include fontSize(1.9, 1.52); //1.9 * 0.8 = 1.52
    line-height: 1.8;

    @include breakpoint-pc {
    }
  }

  &.-t13 {
    @include font-zomin("r");
    @include fontSize(1.8, 1.44); //1.8 * 0.8 = 1.44
    line-height: 1.5;
    
    &.-lh100 {
      line-height: 1;
    }
    &.-ls004 {
      letter-spacing: 0.04em;
    }

    @include breakpoint-pc {
    }
  }

  &.-t14 {
    @include font-zomin("bd");
    @include fontSize(1.7, 1.36); //1.7 * 0.8 = 1.36
    line-height: 2.2;
    
    &.-lh100 {
      line-height: 1;
    }
    &.-ls003 {
      letter-spacing: 0.03em;
    }

    &.-lsr003 {
      letter-spacing: 0.03rem;
    }
    @include breakpoint-pc {
    }
  }

  &.-t15 {
    @include font-ntsan("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    line-height: 2.3;

    @include breakpoint-pc {
    }
  }

  &.-t16 {
    @include font-zomin("bd");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    line-height: 1.5;
    
    &.-lh100 {
      line-height: 1;
    }
    &.-lh230 {
      line-height: 2.3;
    }
    &.-ls002 {
      letter-spacing: 0.02em;
    }
    &.-lsr003 {
      letter-spacing: .03rem;
    }
    &.-productfront {
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: .03rem;
    }

    @include breakpoint-pc {
    }
  }

  &.-t17 {
    @include font-zomin("bk");
    @include fontSize(1.3, 1.04); //1.3 * 0.8 = 1.04
    line-height: 1.5;
    
    &.-lh100 {
      line-height: 1;
    }

    @include breakpoint-pc {
    }
  }

  &.-t18 {
    @include font-ntsan("bd");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    line-height: 1.8;

    &.-lsr004 {
      letter-spacing: .04rem;
    }

    @include breakpoint-pc {
    }
  }

  &.-t19 {
    @include font-zomin("bd");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    line-height: 1.5;

    @include breakpoint-pc {
    }
  }

  &.-t20 {
    @include font-ntsan("m");
    @include fontSize(1.3, 1.04); //1.5 * 0.8 = 1.04
    line-height: 1.5;

    &.-lh100 {
      line-height: 1;
    }

    @include breakpoint-pc {
    }
  }

  &.-t21 {
    @include font-critxt("r");
    @include fontSize(2.2, 1.76); //2.2 * 0.8 = 1.76
    line-height: 1.5;

    &.-lh100 {
      line-height: 1;
    }

    &.-ls005 {
      letter-spacing: 0.05em;
    }

    @include breakpoint-pc {
    }
  }

  &.-t22 {
    @include font-ntsan("m");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
//    @include font-ntsan("bd");
//    @include fontSize(1.8, 1.44); //1.8 * 0.8 = 1.44
    line-height: 1.8;

    &.-lh100 {
      line-height: 1;
    }

    @include breakpoint-pc {
    }
  }

  &.-t23 {
    @include font-ntsan("r");
    @include fontSize(1.3, 1.04); //1.3 * 0.8 = 1.04
    line-height: 1.4;

    &.-lh100 {
      line-height: 1;
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t24 {
    @include font-ntsan("m");
    @include fontSize(2.2, 1.76); //1.5 * 0.8 = 1.76
    line-height: 1.8;
    color: color-black01(1);
  }

  &.-t25 {
    @include font-critxtandsprmin("r");
    @include fontSize(2.2, 1.76); //2.2 * 0.8 = 1.76
    line-height: 1.5;

    &.-lh100 {
      line-height: 1;
    }
    &.-lh140 {
      line-height: 1.4;
    }

    &.-ls005 {
      letter-spacing: 0.05em;
    }

    @include breakpoint-pc {
    }
  }

  &.-t26 {
    @include font-oswald("m");
    @include fontSize(2, 1.6); //2 * 0.8 = 1.6
    line-height: 1.5;

    @include breakpoint-pc {
    }
    
    &.-lh100 {
      line-height: 1;
    }

    &.-ls006 {
      letter-spacing: 0.06em;
    }
  }

  &.-t27 {
    @include font-ntsan("bd");
    @include fontSize(1.3, 1.04); //1.5 * 0.8 = 1.04
    line-height: 1.5;

    &.-lh100 {
      line-height: 1;
    }

    &.-ls006 {
      letter-spacing: 0.06em;
    }

    @include breakpoint-pc {
    }
  }


  ///// フォントサイズ調整(パーセント指定) /////
  $i: 10;
  @for $value from 1 through 9 {
    &.-per {
      &#{ $value * $i } {
        font-size: $value * $i * 1%;
      }
    }
  }

  ///// リンク /////
  &.-link01 {
    text-decoration: underline;
  }


  ///// リスト /////
  &.-list01 {
    list-style: none;
    counter-reset: number;

    li {
      position: relative;
      box-sizing: border-box;
      text-indent: -1.25em;
      padding-left: 1.25em;
      @include font-ntsan("r");
      @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
      line-height: 1.8;
   
      &::before {
        position: relative;
        counter-increment: number;
        content: '(' counter(number) ')';
      }
    }
  }
  
  &.-list02 {
    counter-reset: number;

    li {
      position: relative;
      text-indent: -1.25em;
      padding-left: 1.25em;
      @include font-ntsan("r");
      @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
      line-height: 1.8;

      &::before {
        content: "・";
      }
    }
  }

  &.-list03 {
    list-style: none;
    counter-reset: number;

    li {
      position: relative;
      box-sizing: border-box;
      text-indent: -1.25em;
      padding-left: 1.25em;
      @include font-ntsan("r");
      @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
      line-height: 1.8;
   
      &::before {
        display: inline;
        position: relative;
      }

      &:nth-of-type(1) {
        &:before {
          content: "\02460";
        }
      }
      &:nth-of-type(2) {
        &:before {
          content: "\02461";
        }
      }
      &:nth-of-type(3) {
        &:before {
          content: "\02462";
        }
      }
      &:nth-of-type(4) {
        &:before {
          content: "\02463";
        }
      }
      &:nth-of-type(5) {
        &:before {
          content: "\02464";
        }
      }
      &:nth-of-type(6) {
        &:before {
          content: "\02465";
        }
      }
      &:nth-of-type(7) {
        &:before {
          content: "\02466";
        }
      }
      &:nth-of-type(8) {
        &:before {
          content: "\02467";
        }
      }
      &:nth-of-type(9) {
        &:before {
          content: "\02468";
        }
      }
      &:nth-of-type(10) {
        &:before {
          content: "\02469";
        }
      }
    }
  }
}