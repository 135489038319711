.p0-topics {
  &_list {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    gap: $topics-list-gap-sp;

    @include breakpoint-tb {
      gap: $topics-list-gap-sp;
    }
    @include breakpoint-pc {
      gap: $topics-list-gap-pc;
    }

    &_item {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid color-black01(1);
      box-sizing: border-box;
      transition: border-bottom-color 0.3s;

      @include breakpoint-tb {
        width: calc((100% - #{$topics-list-gap-sp} ) / 2);
      }
      @include breakpoint-pc {
        width: calc((100% - (#{$topics-list-gap-pc} * 2) ) / 3);
      }
      
      &.-half {
        width: 100%;
        
        @include breakpoint-tb {
          width: calc((100% - #{$topics-list-gap-sp} ) / 2);
        }
        @include breakpoint-pc {
          width: 50%;
        }
      }
      
      &.-wide {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-space-between;
        @include flex-align-items-stretch;
        width: 100%;
        margin-bottom: $topics-list-gap-sp;
        padding-bottom: 30px;

        @include breakpoint-tb {
          width: 100%;
          margin-bottom: $topics-list-gap-sp;
          padding-bottom: 0;
          border-bottom-style: none;
        }
        @include breakpoint-pc {
          width: 100%;
          margin-bottom: $topics-list-gap-pc;
        }

        .p0-topics_list_img {
          width: 100%;

          @include breakpoint-tb {
            width: 50%;
            margin-bottom: 0;
          }
          @include breakpoint-pc {
            margin-bottom: 0;
          }
        }
        .p0-topics_list_txtbox {
          width: 100%;
          border-bottom-style: none;
          transition: border-bottom-color 0.3s;

          @include breakpoint-tb {
            width: 46%;
            border-bottom: 1px solid color-black01(1);
          }
        }
      }

      &.-halfafterdummy {
        display: none;
        position: relative;
        width: 0;
        height: 100%;

        @include breakpoint-pc {
          display: block;
          overflow: visible;
          width: calc(50% - #{$topics-list-gap-pc});
          border-bottom-style: none;
        }
      }

      &:hover {
        border-bottom-color: color-red02(1);

        .p0-topics_list_txtbox {
          border-bottom-color: color-red02(1);
        }
        .p0-topics_list_more {
          background-color: color-red02(1);
        }
      }
    }
    
    &_img {
      margin-bottom: 9px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @include breakpoint-pc {
        margin-bottom: 18px;
      }
    }
    
    &_txtbox {
      padding: 0 10px;
      box-sizing: border-box;
    }

    &_dt {
      margin-bottom: 3px;

      @include breakpoint-pc {
        margin-bottom: 6px;
      }
    }
    
    &_ttl {
      @include ellipsisSetLine(2);

      @include breakpoint-pc {
      }
    }
    
    &_txt {
      @include ellipsisSetLine(2);

      @include breakpoint-tb {
        @include ellipsisSetLine(1);
      }
      @include breakpoint-contentinnerhalf {
        @include ellipsisSetLine(2);
      }
      @include breakpoint-pc {
        @include ellipsisSetLine(3);
      }
    }

    &_more {
      display: inline-block;
      position: absolute;
      bottom: 0;
      transform: skew(-45deg);
      transform-origin: bottom right;
      right: 0;
      width: 140px;
      padding: 3px 6px 3px 0;
      box-sizing: border-box;
      text-align: center;
      letter-spacing: 0.05em;
      background-color: color-black01(1);
      transition: background-color 0.3s;
      
      &_inner {
        display: inline-block;
        transform: skew(45deg);
      }
    }
  }
  
  &_article {
    &_dt {
      padding: 0 10px 15px 10px;
      border-bottom: 1px solid color-sub06(1);
      box-sizing: border-box;
      @include font-ntsan("r");
      @include fontSize(2.2, 2.1);
      color: color-txt08(1);
      @include breakpoint-pc {
        padding: 0 10px 10px 10px;
      }
    }
    
    &_ttl {
    }
    
    &_txt {
      p {
        margin-bottom: 0.5em;

        @include breakpoint-pc {
          margin-bottom: 1em;
        }
        
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1em;
      }
    }
  }
}