.l-wrap {
  overflow: hidden;
}


.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
//  overflow: hidden;

  @include breakpoint-pc {
    padding: 60px 0;
  }

  &.-visible {
    overflow: visible;
  }

  &.-hidden {
    overflow: hidden;
  }

  &.-pad0 {
    padding: 0;
  }

  &.-pt0 {
    padding: 0 0 30px;

    @include breakpoint-pc {
      padding: 0 0 60px;
    }
  }

  &.-pb0 {
    padding: 30px 0 0;

    @include breakpoint-pc {
      padding: 60px 0 0;
    }
  }

  &.-smallSpace {
    padding: 15px 0;

    @include breakpoint-pc {
      padding: 30px 0;
    }

    &.-pb0 {
      padding: 15px 0 0;

      @include breakpoint-pc {
        padding: 30px 0 0;
      }
    }
  }

  &.-largeSpace {
    padding: 45px 0;

    @include breakpoint-pc {
      padding: 50px 0;
    }
    @include breakpoint-narrowinner {
      padding: 80px 0;
    }
    @include breakpoint-narrow {
      padding: 90px 0;
    }

    &.-pb0 {
      padding: 45px 0 0;

      @include breakpoint-pc {
        padding: 50px 0 0;
      }
      @include breakpoint-narrowinner {
        padding: 80px 0 0;
      }
      @include breakpoint-narrow {
        padding: 90px 0 0;
      }
    }
  }

  &.-hugeSpace {
    padding: 50px 0;

    @include breakpoint-narrowinner {
      padding: 90px 0;
    }
    @include breakpoint-narrow {
      padding: 120px 0;
    }

    &.-pb0 {
      padding: 50px 0 0;

      @include breakpoint-narrowinner {
        padding: 90px 0 0;
      }
      @include breakpoint-narrow {
        padding: 120px 0 0;
      }
    }
  }

  &.-lowerpage {
    padding: 40px 0;

    @include breakpoint-pc {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;

      @include breakpoint-pc {
        padding: 80px 0 0;
      }
    }
  }


  ///// Hidden /////
  &_hidden {
    overflow: hidden;
  }


  ///// Wrap /////
  &_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    &.-max {
      width: 100%;

      @include breakpoint-max {
        width: $max;
      }
    }  
  }


  ///// セクション背景色 /////
  &.-bgtype01 {
    background-color: color-white01(1);
  }

  &.-bgtype02 {
    background-color: color-black01(1);
  }

  &.-bgtype03 {
    background-color: color-wh(1);
  }
  
  &.-bgtype04 {
    background-color: color-gray01(1);
  }
  
  &.-bgtype05 {
    position: relative;
    background-image: url("../images/front/about/bg.jpg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%;
    
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: color-bk(0.24);
    }
  }
  
  &.-bgtype06 {
    position: relative;
    background-color: color-white01(1);
    
    &:before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 100vw;
      height: calc(250px + 27.083333333333333vw); //250px + (130 / 480 * 100)、250px = 画像の下端からsection下端まで、130 = 画像の中央から画像の下端までの長さ
      background-color: color-white02(1);

      @include breakpoint-tb {
        height: calc(250px + 12.5vw); //250px + (100 / 800 * 100)、250px = 画像の下端からsection下端まで、100 = 画像の中央から画像の下端までの長さ
      }
      @include breakpoint-pc {
        height: calc(400px + 8.035714285714286vw); //400px + (90 / 1120 * 100)、250px = 画像の下端からsection下端まで、90 = 画像の中央から画像の下端までの長さ
      }
      @include breakpoint-narrow {
        height: 490px;
      }
    }
  }
  

  
  ///// セクション内の一部のみ背景色を変える場合 /////
  &_bg {
    position: relative;
    width: 100%;

    &.-bgtype01 {
      background-color: color-white(1);
    }

    &.-bgtype02 {
      background-color: color-wh(1);
    }

    &.-bgtype03 {
      background-color: color-gray01(1);
    }

    &.-bgtype04 {
      background: linear-gradient(180deg, transparent 10vw, color-gray01(1) 10vw);  // 170px / 1620px * 100 = 10.4938271604938

      @include breakpoint-max {
        background: linear-gradient(180deg, transparent 170px, color-gray01(1) 170px);
      }
    }

    &.-bgtype05 {
      background:linear-gradient(90deg, color-white01(1) 0%, color-white01(1) 45.5%, color-wh(1) 45.5%, color-wh(1) 100%);
      
      &.-zim1 {
        z-index: -1;
      }
    }

    &.-bgtype06 {
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100%;
    }
  }


  ///// セクション毎のz-index指定用 /////
  ///// (各セクション、最下部ボタンが下にはみ出すので、z-indexの重なり順を、下から上へと大きくしていく必要がある) /////
  $i: 1;
  @for $value from 1 through 10 {
    &.-zi {
      &#{ $value * $i } {
        z-index: $value * $i;
      }
    }
  }

  &_content {
    width: 90%;
    margin: 0 auto;
    position: relative;
//    overflow: hidden;
    overflow-wrap: anywhere;

    @include breakpoint-content {
      width: $contentinner;
    }

    &.-narrow {
      @include breakpoint-narrow {
        width: $narrowinner;
      }
    }

    &.-productlist {
      @include breakpoint-content {
        width: 90%;
      }
      @include breakpoint-contentinner {
        width: calc( #{$contentinner} - 60px - 60px );
      }
    }

    &.-wide {
      @include breakpoint-content {
        width: 90%;
      }
      @include breakpoint-max {
        width: $maxinner;
      }
    }

    &.-full {
      width: 100%;

      @include breakpoint-content {
        width: 100%;
      }
    }

    &.-wide_full {
      width: 100%;
      
      .l-section_content_inner {
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        &.-narrow {
          @include breakpoint-narrow {
            width: $narrowinner;
          }
        }

        &.-contentinner {
          width: 100%;

          @include breakpoint-contentinner {
            width: $narrow;
          }
        }
      }

      @include breakpoint-max {
        width: $maxinner;
      
        .l-section_content_inner {
          width: 100%;
        }
      }
    }

    &.-visible {
      overflow: visible;
    }
    
    $i: 1;
    @for $value from 1 through 10 {
      &.-zi {
        &#{ $value * $i } {
          z-index: $value * $i;
        }
      }
    }
  }
}
