.p0-hero {
  @include breakpoint-pc {
  }
  
  /* トップページ */
  &.-front {
    position: relative;
    width: 100%;
    height: 100dvh;
//    height: 157.142857142857143vw;  //1100px / 700px * 100

    // 画面幅が7:11(700:1100)以上の時スタイルが適用される
    @media (min-aspect-ratio: 7/11) {
      min-height: 157.142857142857143vw;  // 1100px / 700px * 100
    }

    @include breakpoint-pc {
      height: 71.25vw; //570px / 800px * 100

      @media (min-aspect-ratio: 7/11) {
        min-height: auto;
      }
    }
    @include breakpoint-w900 {
      height: 660px;
    }
    @include breakpoint-narrow {
      height: 59.259259259259259vw; //960px / 1620px * 100
    }
    @include breakpoint-max {
      height: 960px;
    }

    .p0-hero_content {
      height: 100%;
      
      //除算
      &.-divide {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        mix-blend-mode: divide;  //除算
      }

      //乗算
      &.-multiply {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        mix-blend-mode: multiply;  //乗算
      }

      //覆い焼き
      &.-colordodge {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        mix-blend-mode: color-dodge;  //覆い焼き
      }

      //差の絶対値
      &.-difference {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        mix-blend-mode: difference;  //差の絶対値
      }
      
      //ソフトライト
      &.-softlight {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        mix-blend-mode: soft-light;  //ソフトライト
      }
    }
    
    .p0-hero {
      &_bg {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        text-align: center;
        background-image: url("../images/front/kv/bg_sp.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @include breakpoint-pc {
          background-color: color-green01(1);
          background-image: url("../images/front/kv/bg_pc.jpg");
          background-position: center top;
          background-size: auto 100%;
        }
//        @include breakpoint-max {
//          background-image: url("../images/front/kv/bg_max.jpg");
//        }

//        & > img {
//          width: 100%;
//          max-width: 1620px;
//          height: auto;
//        }
        
        &_pc {
          display: none;
          
          @include breakpoint-pc {
            display: inline-block;
          }
        }
        &_sp {
          display: inline-block;
          
          @include breakpoint-pc {
            display: none;
          }
        }

        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-image: url("../images/front/kv/bg_shadow_btm.png");
          background-position: center bottom 5.714285714285714vw;  //40px / 700px * 100
          background-repeat: repeat-x;
          background-size: 2px auto;

          @include breakpoint-pc {
            background-size: 0.123456790123457vw auto;  //2px / 1620px * 100
            background-position: center bottom;
          }
          @include breakpoint-max {
            background-size: 2px auto;
          }
        }
        &::after {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100vw;
          height: 5.714285714285714vw;  //40px / 700px * 100
          background-color: color-bk(1);
          
          @include breakpoint-pc {
            display: none;
          }
        }
      }
      
      //配置確認用の仮画像//
      &_tmp {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 100;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0.5;
//        opacity: 0;
        
        img {
          width: 100%;
          max-width: 1620px;
          height: auto;
        } 
      }
      //配置確認用の仮画像//

      &_txt {
        position: relative;
        box-sizing: border-box;

        img {
          width: 100%;
          height: auto;
        }

        //スプーンはアートである。
        &.-txt1 {
          position: absolute;
          top: calc(50% - 24.285714285714286vw);  //(550px(上下中央) - 380px(上端までの距離)) / 700px * 100
          left: 50%;
          transform: translate(-50%, 0);
          width: 83.571428571428571vw; //585px / 700px * 100
          max-width: 100%;

          @include breakpoint-pc {
            position: relative;
            top: 0;
            transform: translate(-50%, 0);
            padding-top: 23.75vw; //190px / 800px * 100
            width: 101.5%;       //731px / 720px * 100(%)
          }
          @include breakpoint-w900 {
            padding-top: 240px;
            width: 840px;       //840px / 900px * 100
            max-width: 105%;       //840px / 810px * 100(%)
          }
          @include breakpoint-narrow {
            padding-top: 21.604938271604938vw; //350px / 1620px * 100
            width: 79.821428571428571vw;       //894px / 1120px * 100
          }
          @include breakpoint-narrow {
            padding-top: 19.642857142857143vw; //PSDの1120px時の上余白は220px、220px / 1120px * 100
            width: 60.740740740740741vw;       //984px / 1620px * 100
            min-width: 894px; //PSDの1120px時のサイズ
          }
          @include breakpoint-maxinner {
            padding-top: 21.604938271604938vw; //350px / 1620px * 100
          }
          @include breakpoint-max {
            padding-top: 350px;
            width: 984px;
          }
          
          &.-pc {
            display: none;
            
            @include breakpoint-pc {
              display: block;
            }
          }
          &.-sp {
            display: block;
            
            @include breakpoint-pc {
              display: none;
            }
          }
        }
        
        //KVテキスト(画像ではなく、テキストとして配置)
        &.-txt2 {
          position: absolute;
          top: calc(50% + 50vw);  //(550px(上下中央) - 900px(上端までの距離)) / 700px * 100
          width: 100%;

          @include breakpoint-pc {
            position: relative;
            top: 0;
            padding-top: 3.395061728395062vw; //55px / 1620px * 100
          }
          @include breakpoint-w900 {
            padding-top: 50px;
          }
          @include breakpoint-narrow {
            padding-top: 3.395061728395062vw; //55px / 1620px * 100
          }
          @include breakpoint-max {
            padding-top: 55px;
          }
        }
        
        //Angler’z System
        &.-txt3 {
          position: absolute;
          top: calc(50% - 42.857142857142857vw);  //(550px(上下中央) - 250px(上端までの距離)) / 700px * 100
          left: calc(50% - 40.714285714285714vw);  //285px / 700px * 100
          width: 88vw; //616px / 700 * 100
          mix-blend-mode: inherit;
          opacity: 0.75;

          @include breakpoint-pc {
            top: 12.75vw;   //102px / 800px * 100
            left: calc(50% - 16.049382716049383vw);  //260px / 1620px * 100
            width: 59.197530864197531vw; //959px / 1620px * 100
          }
          @include breakpoint-w900 {
            top: 165px;
            left: calc(50% - 20vw);  //180px / 900px * 100
          }
          @include breakpoint-narrow {
            top: 10.802469135802469vw;   //175px / 1620px * 100
            left: calc(50% - 16.049382716049383vw);  //260px / 1620px * 100
          }
          @include breakpoint-max {
            top: 175px;
            left: calc(50% - 260px);
            width: 959px;
          }
          
          img {
            mix-blend-mode: inherit;
          }
        }
        
        //Made by Craftsmen
        &.-txt4 {
          position: absolute;
          top: calc(50% + 27.142857142857143vw);  //(550px(上下中央) - 740px(上端までの距離)) / 700px * 100
          left: calc(50% - 42.142857142857143vw);    //295px / 700px * 100
          width: 72.857142857142857vw; //510px / 700px * 100
          opacity: 0.45;

          @include breakpoint-pc {
            top: 38.125vw;    //305px / 800px * 100
            left: calc(50% - 40.625vw);    //325px / 800px * 100
            width: 68.703703703703704vw;  //1113px / 1620px * 100
          }
          @include breakpoint-w900 {
            top: 360px;
            left: calc(50% - 41.111111111111111vw);    //370px / 900px * 100
          }
          @include breakpoint-narrow {
            top: 32.345679012345679vw;    //524px / 1620px * 100
            left: calc(50% - 42.283950617283951vw);    //685px / 1620px * 100
          }
          @include breakpoint-max {
            top: 524px;
            left: calc(50% - 685px);
            width: 1113px;
          }
        }
        
        //DOHNA
        &.-txt5 {
          position: absolute;
          top: calc(50% - 52.857142857142857vw);  //(550px(上下中央) - 180px(上端までの距離)) / 700px * 100
          left: -2.5vw;
          width: 29.285714285714286vw; //205px / 700px * 100

          @include breakpoint-pc {
            top: 12.75vw;   //102px / 800px * 100
            left: calc(50% - 44.444444444444444vw);    //720px / 1620px * 100
            width: 17vw;    //136px / 800px * 100
          }
          @include breakpoint-w900 {
            top: 165px;
            width: 16.54320987654321vw;    //268px / 1620px * 100
          }
          @include breakpoint-narrow {
            top: 11.111111111111111vw;    //180px / 1620px * 100
          }
          @include breakpoint-max {
            top: 180px;
            left: calc(50% - 720px);
            width: 268px;
          }
        }
        
        //BUX
        &.-txt6 {
          position: absolute;
          top: calc(50% + 25.714285714285714vw);  //(550px(上下中央) - 730px(上端までの距離)) / 700px * 100
          left: calc(50% + 2.142857142857143vw);    //15px / 700px * 100
          width: 48.285714285714286vw; //338px / 700px * 100
          opacity: 0.82;
//          mix-blend-mode: inherit;

          @include breakpoint-pc {
            top: 42.5vw;    //340px / 800px * 100
            left: calc(50% + 21.25vw);    //170px / 800px * 100
            width: 28.125vw;    //225px / 800px * 100
          }
          @include breakpoint-w900 {
            top: 410px;
            left: calc(50% + 22.222222222222222vw);    //200px / 900px * 100
            width: 27.283950617283951vw;    //442px / 1620px * 100
          }
          @include breakpoint-narrow {
            top: 37.345679012345679vw;    //605px / 1620px * 100
            left: calc(50% + 22.530864197530864vw);    //365px / 1620px * 100
          }
          @include breakpoint-max {
            top: 605px;
            left: calc(50% + 365px);
            width: 442px;
          }
          
          img {
//            mix-blend-mode: inherit;
          }
        }
      }
      
      &_img {
        position: relative;
        box-sizing: border-box;

        &.-spoons {
          @include flexbox;
          @include flex-wrap-nowrap;
          @include flex-direction-row;
          @include flex-justify-center;
          @include flex-align-items-center;
          position: relative;
          top: calc(50% + 34.285714285714286vw);  //(550px(上下中央) - 790px(上端までの距離)) / 700px * 100

          @include breakpoint-pc {
            position: relative;
            top: 0;
            padding-top: 10vw;    //80px / 800px * 100
          }
          @include breakpoint-w900 {
            padding-top: 80px;
          }
          @include breakpoint-narrow {
            padding-top: 8.024691358024691vw;    //130px / 1620px * 100
          }
          @include breakpoint-max {
            padding-top: 130px;
          }
        }
        
        &.-spoon1, &.-spoon2, &.-spoon3 {
          position: relative;
          width: 23vw;    //161px / 700px * 100

          @include breakpoint-pc {
            width: 23.125vw;    //185px / 800px * 100
          }
          @include breakpoint-w900 {
            width: 16.728395061728395vw;    //271px / 1620px * 100
          }
          @include breakpoint-max {
            width: 271px;
          }
        }
        &.-spoon1 {
          left: 1.571428571428571vw;  //11px / 700px * 100

          @include breakpoint-pc {
            left: 1.481481481481481vw;  //24px / 1620px * 100
          }
          @include breakpoint-max {
            left: 24px;
          }
        }
        &.-spoon2 {
        }
        &.-spoon3 {
          left: -1.142857142857143vw;  //8px / 700px * 100

          @include breakpoint-pc {
            left: -0.987654320987654vw;    //16px / 1620px * 100
          }
          @include breakpoint-max {
            left: -16px;
          }
        }
      }
    }
    
  }
  
  /* 下層ページ */
  &.-lowerpage {
    position: relative;
    width: 100%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-sizing: border-box;

    @include breakpoint-pc {
    }
    
    &.-archive {
      &:after {
        display: block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 100vw;
        height: 42vw;  //420 / 1000 * 100
        background-position: center center;
        background-repeat: repeat-x;
        background-size: auto 100%;
  
        @include breakpoint-narrowinner {
          height: 420px;
        }
      }
    }
  }

  ///// Products /////
  &.-products {
    padding-bottom: 30px;

    @include breakpoint-pc {
      padding-bottom: 60px;
    }

    &.-archive {
      position: relative;

      &:after {
        background-color: color-bk(1);
        background-image: url("../images/products/mv.jpg");
        background-repeat: no-repeat;
      }
      
      .p0-hero_mv {
//        position: relative;
        position: absolute;
        bottom: -40px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 420px;
         
        @include breakpoint-pc {
          bottom: -80px;
        }

        //覆い焼きカラー
        &.-colordodge {
          mix-blend-mode: color-dodge;
        }

        //塗り70%
        &.-per70 {
          opacity: 0.7;
        }

        &_ttl {
          position: absolute;
          bottom: -42vw;  //420 / 1000 * 100
          right: 0;
          z-index: 1;
          transform: translate(-5.5vw, -3.5vw);  //55 / 1000 * 100、35 / 1000 * 100
  
          @include breakpoint-narrowinner {
            bottom: -420px;
            right: 0;
            transform: translate(-55px, -35px);
          }

          img {
            width: 50vw; //500 / 1000 * 100
            height: auto;

            @include breakpoint-narrowinner {
              width: 500px;
            }
          }

        }
      }
    }
  }

  ///// Topics /////
  &.-topics {
    padding-bottom: 30px;

    @include breakpoint-pc {
      padding-bottom: 60px;
    }

    &.-archive {
      &:after {
        background-image: url("../images/topics/mv.jpg");
        background-position: 34% center;
      }
    }
  }
  
  ///// About Us /////
  &.-about {
    height: calc(850px + 22vw); //850px + 22vwは、表示を見ながら調整した値
    background-color: color-gray03(1);
    background-image: url("../images/about/kv_bg.jpg");
    background-position: center bottom -28vw;  //-28vwは、表示を見ながら調整した値
    background-repeat: repeat-x;
    background-size: auto 211.166666666666667vw; //1267px /600px * 100

    @include breakpoint-tb {
//      background-position: center -25vw; //150px / 600px * 100
      background-size: auto 211.166666666666667vw; //1267px /600px * 100
    }
    @include breakpoint-contentinnerhalf {
//      height: 1180px; //中央の道にある上から1つ目の泡が見える高さ(1460px / 1540px ≒ 0.94)
      height: calc(900px + 22vw); //850px + 22vwは、表示を見ながら調整した値
      background-position: center bottom -28vw;  //-28vwは、表示を見ながら調整した値
      background-size: auto 211.166666666666667vw; //1267px /600px * 100
    }
    @include breakpoint-pc {
//      height: 1280px; //中央の道にある上から1つ目の泡が見える高さ
      height: calc(1160px + 22vw); //1160px + 22vwは、表示を見ながら調整した値
      background-position: center bottom -28vw;  //-28vwは、表示を見ながら調整した値
      background-size: auto 211.166666666666667vw; //1267px /600px * 100
    }
    @include breakpoint-w900 {
      height: 1300px; //中央の道にある上から1つ目の泡が見える高さ
      background-position: center -170px;
      background-size: auto 1690px;
    }
    @include breakpoint-narrowinner {
      height: 1300px; //中央の道にある上から1つ目の泡が見える高さ
      background-position: center -170px;
      background-size: auto 1690px;
    }
    @include breakpoint-narrow {
      height: 1280px; //中央の道にある上から1つ目の泡が見える高さ
      background-position: center -190px;
      background-size: auto 1690px;
    }
    @include breakpoint-contentinner {
      height: 1540px;
      background-position: center bottom;
      background-size: auto 1690px;
    }
    
    .p0-hero_ttl {
      text-align: center;

      img {
        width: 479px;
        height: auto;

        @include breakpoint-pc {
          width: 479px;
        }
      }
    }
  }


  &.-sub {
    .p0-hero_img {
      display: block;
    }
  }

  &_wrap {
    display: flex;
    align-items: center;
    padding: 15px 0 0 0;

    @include breakpoint-pc {
      padding: 15px 0 0 0;
    }
  }

  &_headline {
    & img {
      display: block;
    }

    &.-page {
      position: absolute;
      top: calc( 50% + 15px );
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      @include font-ntsan("r");
      @include fontSize(3.6, 3);
      color: color-txt09(1);
      -moz-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.41), 0px 0px 16px rgba(0, 0, 0, 0.43);
      -webkit-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.41), 0px 0px 16px rgba(0, 0, 0, 0.43);
      -ms-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.41), 0px 0px 16px rgba(0, 0, 0, 0.43);
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.41), 0px 0px 16px rgba(0, 0, 0, 0.43);
      @include breakpoint-pc {
      }
      line-height: 1;
    }
  }

}