.l-header {
  width: 100%;
  height: $drawer-space-sp;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  padding: 0;
  color: color-wh(1);

  @include breakpoint-pc {
//    position: absolute;
    width: 100%;
    height: $drawer-space-pc;
    box-sizing: border-box;
    mix-blend-mode: exclusion;  //除外
  }
  @include breakpoint-content {
  }
  @include breakpoint-narrow {
  }
  
  &.js-isscroll {
//    position: fixed;
//    top: 0;

    @include breakpoint-pc {
    }
    @include breakpoint-content {
    }
  }
  &.js-noscroll {
//    position: absolute;
//    top: 0;

    @include breakpoint-pc {
    }
  }

  &_inner {
    width: 100%;
    height: 100%;
    max-width: $max;  //ヘッダーグロナビの中身の最大横幅
    margin: 0 auto;
  }
}

///// header背景グラデーション(トップページのKV範囲内でのみ表示する) /////
// この背景グラデーションは、mix-blend-modeを適用したくないので、headerの外に出して、position: fixedとして表示・動作させる //
.l-wrap {
  .l-headerbg {
    display: none;
  }

  &.-front {
    .l-headerbg {
      display: none;
      width: 100%;
      height: 226px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;  //headerが1001なので、その下に敷く
      padding: 0;
      background-image: url("../images/common/header/bg_shadow_top.png");
      background-position: center top;
      background-repeat: repeat-x;
      background-size: 2px auto;
    
      @include breakpoint-pc {
        display: block;
        width: 100%;
        height: 226px;
        box-sizing: border-box;
      }
    }
  }
}
