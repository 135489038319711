.p4-pager {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-justify-center;
  @include flex-align-items-center;
  margin: 0 auto;
  @include font-critxt("r");
  font-size: 1.8rem;
  color: color-black01(1);
  text-align: center;
  line-height: 1;

  @include breakpoint-tb {
    @include fontSize(3.2, 2.56); //3.2 * 0.8 = 2.56
  }

  .page-numbers {
    display: block;
    width: 19px;
    margin: 0 8px;
    padding: 0;
    border-bottom: 2px solid transparent;
    box-sizing: border-box;
    color: color-black01(1);
    @include breakpoint-tb {
//      margin: 0 6px;
//      padding: 6px 0;
    }
    @include breakpoint-pc {
//      margin: 0 12px;
    }

    &.current {
      color: color-red01(1);
      border-bottom-color: color-red01(1);
    }

    &.dots {
    }

    &.prev, &.next {
//    &.prev, &.next, &.first, &.last {
      display: block;
      width: 16px;
//      height: 15px; //12px + 3px(下線の高さ)
//      width: 20px;  // * 0.7
//      height: 27px;
      margin: 0 8px;
      padding: 0;
      box-sizing: border-box;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
      @include breakpoint-tb {
//        width: 24px;  // * 0.8
//        height: 29px;
        margin: 0 12px;
      }
      @include breakpoint-pc {
        width: 16px;
//        height: 15px;
      }
    }
    &.prev {
      margin-left: 0;
    }
    &.next {
      margin-right: 0;
    }
/*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/

    &:hover {
      filter: alpha(opacity=70);
      opacity: .7;
      transition: all 0.3s ease-in-out;
    }

    &.-dummy {
      display: block;
    }
  }
  
  /* pager非表示時(1ページのみの場合) */
  &-nodisp {
    height: 15px;
    @include breakpoint-pc {
      height: 30px;
    }
  }
}