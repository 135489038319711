$max: 1620px; // 最大幅 - 1620px
$maxinner: 1500px; // 最大インナー幅(最大幅から左右余白を引いた幅) - 1500px
$cards: 1360px;   //カードリスト幅
$content: 1320px; // コンテンツ幅 - 1320px
$contentinner: 1200px; // コンテンツインナー幅(コンテンツ幅から左右余白を引いた幅) - 1200px
$narrow: 1120px; // 狭いコンテンツ幅 - 1120px
$narrowinner: 1000px; // 狭いコンテンツインナー幅(狭いコンテンツ幅から左右余白を引いた幅) - 1000px
$w900: 900px; //表示切り替え幅の1つ - 900px
$pc: 800px; // PC - 800
$maxinnerhalf: 750px; // メインボックス幅の半分 - 750px
$w720: 720px; //表示切り替え幅の1つ - 720px
$contentinnerhalf: 600px; // メインコンテンツ幅の半分 - 600px
$contenthalf: 500px; // コンテンツインナー幅の半分 - 600px
$tb: 480px; // タブレット - 480
$sp: 375px; // スマホ - 375

@mixin max-breakpoint-narrowinner {
  @media all and (max-width: calc(#{$narrowinner} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-pc {
  @media all and (max-width: calc(#{$pc} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-contentinnerhalf {
  @media all and (max-width: calc(#{$contentinnerhalf} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-tb {
  @media all and (max-width: calc(#{$tb} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-sp {
  @media all and (max-width: calc(#{$sp} - 1px)) {
    @content;
  }
}

@mixin breakpoint-max {
  @media all and (min-width: ($max)) {
    @content;
  }
}
@mixin breakpoint-maxinner {
  @media all and (min-width: ($maxinner)) {
    @content;
  }
}
@mixin breakpoint-content {
  @media all and (min-width: ($content)) {
    @content;
  }
}
@mixin breakpoint-contentinner {
  @media all and (min-width: ($contentinner)) {
    @content;
  }
}
@mixin breakpoint-narrow {
  @media all and (min-width: ($narrow)) {
    @content;
  }
}
@mixin breakpoint-narrowinner {
  @media all and (min-width: ($narrowinner)) {
    @content;
  }
}
@mixin breakpoint-w900 {
  @media all and (min-width: ($w900)) {
    @content;
  }
}
@mixin breakpoint-pc {
  @media all and (min-width: ($pc)) {
    @content;
  }
}
@mixin breakpoint-maxinnerhalf {
  @media all and (min-width: ($maxinnerhalf)) {
    @content;
  }
}
@mixin breakpoint-w720 {
  @media all and (min-width: ($w720)) {
    @content;
  }
}
@mixin breakpoint-contentinnerhalf {
  @media all and (min-width: ($contentinnerhalf)) {
    @content;
  }
}
@mixin breakpoint-contenthalf {
  @media all and (min-width: ($contenthalf)) {
    @content;
  }
}
@mixin breakpoint-tb {
  @media all and (min-width: ($tb)) {
    @content;
  }
}
@mixin breakpoint-sp {
  @media all and (min-width: ($sp)) {
    @content;
  }
}


////// display switch //////
.disp {
  &-block {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: block;
      }
    }
  
    &-sptb {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: block;
      }
    }
  
    &-sp {
      display: block;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: block;
      }
    }
  
    &-sponly {
      display: block;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }

  &-inline {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: inline;
      }
    }
  
    &-sptb {
      display: inline;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: inline;
      }
    }
  
    &-sp {
      display: inline;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: inline;
      }
    }
  
    &-sponly {
      display: inline;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }
}