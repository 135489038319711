.c-product {
  overflow: visible;

  @include breakpoint-pc {
//    overflow: hidden;
  }
  
  &_list {
    padding: 80px 0;
    box-sizing: border-box;
    
    &.-archive {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      gap: $product-list-gap-sp 0;
      background-color: color-wh(1);
  
      @include breakpoint-tb {
        gap: $product-list-gap-sp 0;
      }
      @include breakpoint-pc {
        gap: $product-list-gap-pc 0;
      }
    }
  }
  
  &_item {
    display: block;
    box-sizing: border-box;

    &.-archive {
      display: block;
      position: relative;
      overflow: hidden;
      width: 100%;
      box-sizing: border-box;

      .c-product_inner {
        border-right-color: transparent;
      }
      
      &:first-of-type {
        &:last-of-type {
          .c-product_inner {
            border-right-color: transparent !important;
          }
        }
      }

      @include breakpoint-tb {
        width: calc((100% / 2);

        .c-product_inner {
          border-right-color: color-gray02(1);
        }
        
        &:nth-of-type(2n) {
          .c-product_inner {
            border-right-color: transparent;
          }
        }
      }
      @include breakpoint-pc {
        width: calc((100% / 3);
        
        &:nth-of-type(2n) {
          .c-product_inner {
            border-right-color: color-gray02(1);
          }
        }
        &:nth-of-type(3n) {
          .c-product_inner {
            border-right-color: transparent;
          }
        }
      }
    }
  }
  
  &_inner {
    display: block;
    padding: 0 29px 5px;
    border-right: 1px solid color-gray02(1);
    box-sizing: border-box;
    background-color: color-wh(1);

    &:hover {
      .c-product_more {
        background-color: color-red02(1);
      }
    }
  }
  
  &_img {
    margin-bottom: 5px;

    @include breakpoint-pc {
      margin-bottom: 10px;
    }
  }
  
  &_img {
    position: relative;
    margin-bottom: 5px;

    @include breakpoint-pc {
      margin-bottom: 10px;
    }
  }
  
  &_cat {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: absolute;
    bottom: 5px;
    right: 0;

    @include breakpoint-pc {

    }
    
    // singleページの場合
    &.-single {
      @include flex-justify-center;
      position: relative;
      bottom: auto;
      top: 0;
      right: auto;
      left: 0;
      
      .c-product_cat_inner {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-direction-row;
        @include flex-justify-flex-start;
        @include flex-align-items-end;
        position: relative;
        padding: 4px 12px;
        border-radius: 100vh;
        box-sizing: border-box;
        background-color: color-blue01(1);
      }
      
      .c-product_cat_icon {
        transform: translateY(-1px);
      }
    }
    
    &_txt {
      position: relative;
      line-height: 1;
    }
    
    &_icon {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-end;
      @include flex-shrink-0;
      position: relative;
      width: 18px;
      height: 10px;
      content: "";
      margin-right: 2px;

      @include breakpoint-pc {
        width: 22px;
        height: 12px;
      }
      
      img {
        width: 100%;
        height: auto;
      }
    }
/*
    &:before {
      display: inline-block;
      position: relative;
      width: 18px;
      height: 10px;
      content: "";
      margin-right: 2px;
      background-image: url("../images/common/icon/category.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-pc {
        width: 22px;
        height: 12px;
      }
    }
*/
  }
  
  &_parentttl {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-baseline;
    padding-bottom: 2px;
    box-sizing: border-box;
    
    &_jp {
      transform: translateY(-2px);
      margin-left: 6px;
    }
  }

  
  &_lineups {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-stretch;
    
    &.-left {
      @include flex-justify-flex-start;
    }

    &_item {
      &:after {
        display: inline;
        position: relative;
        content: "/";
      }
      
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }

  &_ttlen {
    margin-bottom: 3px;

    @include breakpoint-pc {
      margin-bottom: 5px;
    }
  }
  
  &_ttljp {
    margin-bottom: 3px;

    @include breakpoint-pc {
      margin-bottom: 5px;
    }
  }

  &_txt {
    margin-bottom: 3px;

    @include breakpoint-pc {
      margin-bottom: 5px;
    }
  }

  &_more {
    max-width: 140px;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 0 3px 0;
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: color-gray02(1);
    transition: background-color 0.3s;
  }
}