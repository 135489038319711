.p0-separatebox {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  position: relative;
  width: 100%;

  &_item {
    width: 100%;
    margin-bottom: 20px;

    @include breakpoint-tb {
      width: 48%;
      margin-bottom: 4vw;
    }
    @include breakpoint-pc {
      width: 22%;
    }
    @include breakpoint-content {
      margin-bottom: 40px;
    }
  }
  
  ///// Productsページ - Spec /////
  &.-productspec {
    .p0-separatebox_item {
      &.-imgbox {
        width: 100%;
        margin-bottom: 15px;
        
        @include breakpoint-tb {
          width: 100%;
          margin-bottom: 15px;
        }
        @include breakpoint-pc {
          width: 50%;
          margin-bottom: 30px;
        }
        @include breakpoint-content {
          width: 50%;
        }
      }
      
      &.-txtbox {
        width: 100%;
        margin-bottom: 35px;
        
        @include breakpoint-tb {
          width: 100%;
          margin-bottom: 35px;
        }
        @include breakpoint-pc {
          width: calc(50% - 40px);
          margin-bottom: 30px;
        }
        @include breakpoint-content {
          width: calc(50% - 40px);
        }
        
        div:first-child {
          margin-top: -0.25em;
        }
      }
    }
  }
  
  ///// Productsページ - Color Variation /////
  &.-productcolor {
    @include flex-justify-flex-start;

    gap: $productcv-list-gap-sp;

    @include breakpoint-tb {
      gap: $productcv-list-gap-tb;
    }
    @include breakpoint-pc {
      gap: $productcv-list-gap-pc;
    }
/*
    //2023/06/23調整前バックアップ
    gap: $productcv-list-gap-sp;

    @include breakpoint-tb {
      gap: $productcv-list-gap-sp;
    }
    @include breakpoint-pc {
      gap: $productcv-list-gap-pc;
    }
*/
    .p0-separatebox_item {
      margin-bottom: 0;

      @media all and (min-width: 320px) {
        width: calc((100% - #{$productcv-list-gap-sp} ) / 2);
        margin-bottom: 0;
      }
      @include breakpoint-tb {
        width: calc((100% - (#{$productcv-list-gap-tb} * 2) ) / 3);
        margin-bottom: 0;
      }
      @include breakpoint-pc {
        width: calc((100% - (#{$productcv-list-gap-pc} * 2) ) / 3);
        margin-bottom: 0;
      }
/*
    //2023/06/23調整前バックアップ
      @include breakpoint-tb {
        width: calc((100% - #{$productcv-list-gap-sp} ) / 2);
        margin-bottom: 0;
      }
      @include breakpoint-pc {
        width: calc((100% - (#{$productcv-list-gap-pc} * 2) ) / 3);
        margin-bottom: 0;
      }
*/
    }  
  }
}