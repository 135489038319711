.p5-form {
  width: 100%;
  max-width: 600px;
  margin: auto;
  
  &_box {
    padding: 20px 0;
    box-sizing: border-box;

    @include breakpoint-pc {
      padding: 35px 0;
    }
    
    &.-privacy {
      margin-bottom: 15px;
      
      @include breakpoint-pc {
        margin-bottom: 30px;
      }
    }
  }

  &_input {
    padding: 3px 0 4px;
    border-bottom: 2px solid color-black01(1);
    box-sizing: border-box;
    @include font-ntsan("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.2
    line-height: 1.5;
    color: color-gray01(1);

    @include breakpoint-pc {
      padding: 8px 0 9px;
    }

    &:focus {
//      border-bottom-color: color-red01(1);
    }

    &.-txt {
      width: 100%;
    }

    &.-txtarea {
      width: 100%;
      height: 200px;
      margin-top: 10px;
      padding: 3px 15px 4px;
      border: 1px solid color-black01(1);

      @include breakpoint-pc {
        margin-top: 20px;
        padding: 8px 15px 9px;
      }

      &:focus {
//        border-color: color-red01(1);
      }
    }

    &.-check {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      margin-right: 4px;

      @include breakpoint-pc {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
  
  &_inputbox {
    box-sizing: border-box;
    @include font-ntsan("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    line-height: 1;
    
    .horizontal-item {
      label {
        @include flexbox;
        @include flex-wrap-nowrap;
        @include flex-direction-row;
        @include flex-justify-flex-start;
        @include flex-align-items-center;
        @include font-ntsan("m");
      }
    }
      
    &.-required {
      .horizontal-item {
        label {
          &:after {
            display: inline-block;
            position: relative;
            content: "*";
            color: color-red01(1);
          }
        }
      }
    }
    
    &.-error {
      input, textarea {
        border-color: color-red01(1) !important;
      }
    }
  }

  & .-link {
    margin-bottom: 15px;

    & a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: color-wh(1);
      border-radius: 100px;
      @include font-ntsan("r");
      @include breakpoint-maxinnerhalf {
        width: 310px;
        max-width: 310px;
        padding: 10px 20px;
        font-size: 1.7rem;
      }
    }
  }
  &_btn {
    margin: 0 10px;
  }
  &_btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 !important;
    border-bottom: none !important;
    @include breakpoint-maxinnerhalf {
      margin: 60px 0 !important;
    }
  }
}

.mwform-checkbox-field-text {
  vertical-align: 5px;
  padding-left: 5px;
}
.mwform-checkbox-field {
  line-height: 1;
}

.mw_wp_form .error {
  display: block;
  padding: 10px 0;
  box-sizing: border-box;
  @include font-ntsan("m");
  @include fontSize(1.4, 1.12); //1.4 * 0.8 = 1.12
  color: color-red01(1);
  line-height: 1.5;

  @include breakpoint-pc {
    padding: 12px 0;
  }
}

.mw_wp_form_input {
  .p5-form_display {
    &.-inputpage {
      display: block;
    }
    &.-confirmpage, &.-completepage {
      display: none;
    }
  }

  & .p5-form_btn.-back, & .p5-form_btn.-submit, & .p5-form_btn.-top {
    display: none;
  }
  & .p5-form_thx {
    display: none;
  }
}

.mw_wp_form_confirm {
  .p5-form_display {
    &.-confirmpage {
      display: block;
    }
    &.-inputpage, &.-completepage {
      display: none;
    }
  }
  
  .p5-form_box {
    border-bottom: 1px solid color-gray02(1);

    @include breakpoint-pc {
    }
    
    &.-last {
      margin-bottom: 50px;

      @include breakpoint-pc {
        margin-bottom: 100px;
      }
    }
    
    &.-privacy {
      display: none;
    }
  }
  
  .p5-form_inputbox {
    padding-top: 15px;

    @include breakpoint-pc {
      padding-top: 25px;
    }
  }
  .p5-form_btn.-submit {
    margin-bottom: 5px;
    
    @include breakpoint-pc {
      margin-bottom: 10px;
    }
  }

  & .p5-form_btn.-confirm, & .p5-form_btn.-top {
    display: none;
  }
  & .error {
    display: none;
  }
  & .formarea-privacy-wrap {
    display: none;
  }
  & .p5-form_thx {
    display: none;
  }
}

.mw_wp_form_complete {
  .p5-form_display {
    &.-completepage {
      display: block;
    }
    &.-inputpage, &.-confirmpage {
      display: none;
    }
  }

  & .p5-form_btn.-back, & .p5-form_btn.-confirm, & .p5-form_btn.xzssxsaazaa-submit {
    display: none;
  }
  & ~ .p5-form_intro {
    display: none;
  }
}