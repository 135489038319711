/* トップページのみで使用するCSS */
.p0-front {
  // Products
  &_products {
    &_imgs {
      position: relative;
    }
  }


  // Lineup
  &_lineup {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    position: relative;
    
    &.-rev {
      @include breakpoint-narrowinner {
        @include flex-row-reverse;
      }
      
      .p0-front_lineup_body {
        @include breakpoint-narrowinner {
          @include flex-justify-flex-end;
        }

        &:after {
          left: 100%;
          transform: translateX(0);
        }
      }
    }

    &_head {
      position: relative;
      z-index: 2;
      width: 100%;

      @include breakpoint-narrowinner {
        @include flex-shrink-0;
        width: 240px;
      }
    }
    
    &_body {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      position: relative;
      width: 100%;

      @include breakpoint-narrowinner {
        width: calc(100% - 240px);
      }
      
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200vw;
        height: 100%;
        content: "";
        background-color: color-wh(1);
      }
      
      &:after {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transform: translateX(-100%);
        width: 100vw;
        height: 100%;
        content: "";
        background-color: color-white01(1);

        @include breakpoint-narrowinner {
          display: block;
        }
      }
    }

    &_slidebox {
      position: relative;
      width: 100%;
      padding: 80px 0;
      box-sizing: border-box;
//      background-color: color-wh(1);

      @include breakpoint-narrowinner {
        width: calc(100vw - ((100vw - 90vw) / 2) - 240px);
      }
      @include breakpoint-narrow {
        width: calc(100vw - ((100vw - 1000px) / 2) - 240px);
      }
      @include breakpoint-max {
        width: 1070px;
      }
    }

    &_prev, &_next {
      position: absolute;
      top: 50%;
      z-index: 2;
      color: color-wh(1);
      mix-blend-mode: exclusion;  //除外
    }
    &_prev {
      left: 0;
      transform: translate(-50%, -50%);
      
      // 左右反転
      &.-rev {
        left: 0;
        transform: translate(-50%, -50%);
      
        @include breakpoint-narrowinner {
          transform: translate(50%, -50%);
        }
        @include breakpoint-narrow {
          left: calc(5vw - 0.925925925925926vw); //-15 / 1620 * 100 = 0.925925925925926
          transform: translate(-50%, -50%);
        }
        @include breakpoint-max {
        left: auto;
        right: calc(100% + ((100vw - 1620px) / 2 - 50px));
        transform: translate(30px, -50%);
  //        left: 50px;
        }
      }
    }
    &_next {
      right: 0;
      transform: translate(50%, -50%);
  
      @include breakpoint-narrowinner {
        transform: translate(-50%, -50%);
      }
      @include breakpoint-narrow {
        right: calc(5vw - 0.925925925925926vw); //-15 / 1620 * 100 = 0.925925925925926
        transform: translate(50%, -50%);
      }
      @include breakpoint-max {
        right: auto;
        left: calc(100% + ((100vw - 1620px) / 2 - 50px));
        transform: translate(-30px, -50%);
  //      right: 50px;
      }
      
      // 左右反転
      &.-rev {
        right: 0;
        transform: translate(50%, -50%);
  
        @include breakpoint-max {
          left: auto;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }

    &_container {
//      overflow: hidden;
      .swiper-wrapper {
        position: relative;
      }
    }
  }


  // About Us
  &_about {
    &_copytxt {
      text-align: center;

      img {
        width: 382px; //478px * 0.8 = 382.4
        height: auto;

        @include breakpoint-pc {
          width: 478px;
        }
      }
    }
  }
}
