// sub pages settings
$pgList: about;

//header nav menu
$drawer-space-pc: 70px;
$drawer-space-sp: 70px;

//product gap
$product-list-gap-pc: 80px;
$product-list-gap-sp: 60px;

//Product Color Variation gap
$productcv-list-gap-pc: 40px;
$productcv-list-gap-tb: 30px;
$productcv-list-gap-sp: 20px;
/*
    //2023/06/23調整前バックアップ
$productcv-list-gap-pc: 40px;
$productcv-list-gap-sp: 30px;
*/

//list gap
$topics-list-gap-pc: 40px;
$topics-list-gap-sp: 30px;