.p3-img {
  &.-i01 {
    img {
      width: 100%;
      height: auto;
    }
  }
  
  ///// クリックするとモーダルが表示される画像 /////
  &.-modaltrigger {
    cursor: pointer;
  }

  ///// トップページの「Products」にある画像 /////
  &.-fp01, &.-fp02, &.-fp03, &.-fp04, &.-fp05 {
    img {
      width: 100%;
      height: auto;
    }
  }

  &.-fp01 {
    position: relative;
    left: calc(94.1666666666667vw - 100vw);
    z-index: 2;
    width: 94.1666666666667vw;
    margin: 0 0 4.166666666666667vw auto; //20 / 480 * 100

    @include breakpoint-tb {
      left: calc(94.1666666666667vw - 100vw);
      width: 79.5vw; //477px / 600px * 100
      margin-left: 0;
      margin-bottom: 3.333333333333333vw;  //20px / 600px * 100
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-w720 {
//      left: calc(94.1666666666667vw - 100vw);
      width: 610px;
//      margin-left: 0;
      margin-bottom: 25px;
    }
    @include breakpoint-pc {
//      left: calc(94.1666666666667vw - 100vw);
//      width: 610px;
      margin-bottom: 30px;
//      margin-left: 0;
    }
    @include breakpoint-narrowinner {
//      width: 610px;
      left: auto;
      right: 45%;
      margin-left: auto;
      margin-bottom: 0;
    }
    @include breakpoint-narrow {
      right: auto;
      left: calc((1000px - 100vw) / 2);
//      width: 610px;
      margin-left: 0;
      margin-bottom: 0;
    }
    @include breakpoint-contentinner {
      left: auto;
      right: 49%;
      width: 50.617283950617284vw; //820 / 1620 * 100
      margin: 0 0 2.469135802469136vw auto; //40 / 1620 * 100
    }
    @include breakpoint-max {
      width: 820px;
      margin-bottom: 40px;
    }
  }
  &.-fp02 {
    position: relative;
    top: 0;
    left: calc(100vw - 85.416666666666667vw - 5vw); //5vwは、左余白分
    z-index: 1;
    width: 85.416666666666667vw; //410 / 480 * 100

    @include breakpoint-tb {
      left: auto;
      right: -5vw;
      width: 72.833333333333333vw; //437px / 600px * 100
      margin-left: auto;
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-w720 {
      width: 557px;
    }
    @include breakpoint-pc {
      width: 67.926829268292683vw;//557px / 820px * 100
    }
    @include breakpoint-narrowinner {
      left: 43.5%;
      width: 615px;
      margin-top: -80px;
      margin-left: 0;
    }
    @include breakpoint-narrow {
      left: auto;
      right: calc((1000px - 100vw) / 2);
      margin-top: -100px;
      margin-left: auto;
    }
    @include breakpoint-contentinner {
      position: absolute;
      top: 12.654320987654321vw; //205 / 1620 * 100
      right: auto;
      left: 55%;
      width: 50.617283950617284vw; //820 / 1620 * 100
      margin-top: 0;
      margin-left: 0;
    }
    @include breakpoint-max {
      top: 205px;
      width: 820px;
    }
  }
  &.-fp03 {
    position: relative;
    left: 0;
    width: 72.5vw; //348 / 480 * 100
    margin: -2.083333333333333vw auto 0 0; //10px / 480 * 100

    @include breakpoint-tb {
      width: 61.333333333333333vw; //368px / 600px * 100
      margin-top: -5.833333333333333vw;  //35px / 600px * 100
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-w720 {
      left: -1%;
      width: 465px;
      margin-top: -40px;
    }
    @include breakpoint-pc {
      left: 4%;
    }
    @include breakpoint-narrowinner {
      left: 9%;
      margin-top: -125px;
    }
    @include breakpoint-narrow {
      left: 17%;  //170px / 1000px * 100
      margin-top: -130px;
    }
    @include breakpoint-contentinner {
      top: auto;
      left: auto;
      right: 29%;
      width: 36.111111111111111vw; //585 / 1620 * 100
      margin: 0 0 0 auto;
    }
    @include breakpoint-max {
      width: 585px;
    }
  }
  &.-fp04 {
    position: absolute;
    top: 26.041666666666667vw; //125 / 480 * 100
    left: 43.75vw;  //210 / 480 * 100
    z-index: 2;
    width: 42.708333333333333vw; //205 / 480 * 100

    @include breakpoint-tb {
      top: 24.166666666666667vw; //145px / 600px * 100
      left: 40.740740740740741%;  //220px / 540px * 100
      width: 36.166666666666667vw; //217px / 600px * 100
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-w720 {
      top: 200px;
      left: 50%;
      width: 232px;
    }
    @include breakpoint-pc {
      top: 230px;
      left: 59%;
    }
    @include breakpoint-narrowinner {
      top: 80px;
      left: 61.5%;
    }
    @include breakpoint-narrow {
      top: 60px;
      left: 62.5%; //625px / 1000px * 100
    }
    @include breakpoint-contentinner {
      top: 1.234567901234568vw; //20 / 1620 * 100
      right: auto;
      left: 71%;
      width: 16.975308641975309vw; //275 / 1620 * 100
    }
    @include breakpoint-max {
      top: 20px;
      width: 275px;
    }
  }
  &.-fp05 {
    //479px未満は、fp02の中に表示
    position: absolute;
    bottom: -10.416666666666667vw; //-50 / 480 * 100
    left: 0;
    width: 28.125vw; //135 / 480 * 100
    transform: translateX(-50%);

    //480px以上は、fp03の中に表示
    @include breakpoint-tb {
      width: 23.833333333333333vw;//143px / 600 * 100
      bottom: -1.666666666666667vw; //10px / 600px * 100
      transform: translateX(-83%);
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-w720 {
      width: 153px;
      bottom: 10px;
      transform: translateX(-71%);
    }
    @include breakpoint-pc {
      bottom: 1.219512195121951vw; //10px / 820px * 100
      transform: translateX(-83%);
    }
    @include breakpoint-narrowinner {
      bottom: auto;
      top: 55px;
      transform: translateX(-80%);
    }
    @include breakpoint-narrow {
      bottom: auto;
      top: 55px;
      transform: translateX(-84%);
    }
    @include breakpoint-contentinner {
      bottom: auto;
      top: 5.246913580246914vw; //85 / 1620 * 100
      left: -2.469135802469136vw; //40 / 1620 * 100
      width: 11.17283950617284vw; //181 / 1620 * 100
      transform: translateX(-100%);
    }
    @include breakpoint-max {
      top: 85px;
      width: 181px;
      left: -40px;
    }
    
    &.-pc {
      display: block;
      
      @include breakpoint-narrowinner {
        display: none;
      }
    }
    &.-ni {
      display: none;
      
      @include breakpoint-narrowinner {
        display: block;
      }
    }
  }
  

  ///// トップページの「Topics」にある画像 /////
  &.-ft01, &.-ft02 {
    display: inline-block;

    img {
      width: 100%;
      height: auto;
    }
  }

  // 縦長画像版
  &.-ft01 {
    position: relative;
    top: -5.357142857142857vw; //-60 / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)
    transform: translate(4.464285714285714vw, 0); //50 / 1120 * 100ブラウザ幅1120px未満から可変幅にするため)
    width: 17.857142857142857vw; //200 / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)

    @include breakpoint-narrow {
      top: -60px;
      transform: translate(50px, 0);
      width: 200px;
    }
  }
  &.-ft02 {
    position: relative;
    transform: translate(7.589285714285714vw, 0); //85 / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)
    width: 17.857142857142857vw; //200 / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)

    @include breakpoint-narrow {
      transform: translate(85px, 0);
      width: 200px;
    }
  }
  // 縦長画像版

/*
  // 横長画像版
  &.-ft01 {
    position: relative;
    top: -4.464285714285714vw; //-50px / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)
    left: 12.053571428571429vw; //135px / 1120px * 100
    width: 28.75vw; //322px / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)

    @include breakpoint-narrow {
      top: -50px;
      left: 135px;
      width: 322px;
    }
  }
  &.-ft02 {
    position: relative;
    top: 0;
    left: 6.25vw; //70px / 1120px * 100
    width: 28.839285714285714vw; //323 / 1120 * 100(ブラウザ幅1120px未満から可変幅にするため)

    @include breakpoint-narrow {
      left: 70px;
      width: 323px;
    }
  }
  // 横長画像版
*/

  ///// About Usページ /////
  &.-about01 {
    text-align: center;

    img {
      width: 100%;
      max-width: 820px;
      height: auto;
    }
  }
  
  &.-about02 {
    text-align: right;

    img {
      width: 116px; //146px * 0.8 = 116.8
      height: auto;

      @include breakpoint-pc {
        width: 146px;
      }
    }
  }
  
  &.-about03 {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 420px;
    
    &:before {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100vh;
      background-image: url("../images/about/spacer.jpg");
      background-position: center bottom;
      background-repeat: no-repeat;

      background-size: cover;
//      background-size: 1620px auto;
    }
  }
}