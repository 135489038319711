.p2-ftsns {
  &_inner {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 12px;  //16px * 0.8 = 12.8px
    
    @include breakpoint-pc {
      gap: 16px;
    }
  }

  &_item {
    width: 32px;  //40px * 0.8 = 32px
    box-sizing: border-box;

    @include breakpoint-pc {
      width: 40px;
    }

    a {
      display: block;
      box-sizing: border-box;
      
      &:hover {
        .icon-fb-bg, .icon-tw-bg, .icon-ig-bg {
          fill: color-red02(1);
        }
      }
    }
    
  
    .icon-fb-bg, .icon-tw-bg, .icon-ig-bg {
      transition: fill 0.15s linear;
      fill: color-black01(1);
    }
    .icon-fb-mark, .icon-tw-mark, .icon-ig-mark {
      fill: color-wh(1);
    }

  }
}