.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @include breakpoint-pc {
  }
  @include breakpoint-max {
//    width: 1120px;
  }

  &_content {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    box-sizing: border-box;
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @include breakpoint-pc {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include flex-align-items-stretch;
      position: relative;
      transform: translateY(0);
      width: 100%;
      height: 100%;
      background-image: none;
      background-color: transparent;
    }
  }

  &_list {
    width: 100%;
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    padding-top: 70px;
    box-sizing: border-box;
    color: inherit;

    @include breakpoint-sp {
      padding-top: 85px;
    }
    @include breakpoint-pc {
      @include flex-direction-row;
      @include flex-justify-space-between;
      @include flex-justify-flex-end;
      @include flex-align-content-center;
      gap: 0 10px;
      width: 100%;
      height: 100%;
      padding: 0;
    }
    @include breakpoint-narrowinner {
      gap: 0 30px;
    }
    @include breakpoint-content {
      gap: 0 40px;
    }
    @include breakpoint-maxinner {
      gap: 0 50px;
    }
  }

  &_item {
    display: block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
//    transition: all linear;
    color: inherit;

    @include breakpoint-pc {
    }
  }

  &_itemInner {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-start;
    height: 100%;
    padding: 12px 0;
    box-sizing: border-box;
    text-align: left;
    color: inherit;
//    transition: all linear;

    @include breakpoint-sp {
      padding: 17px 0;
    }
    @include breakpoint-pc {
      @include flex-direction-row;
      @include flex-justify-center;
      @include flex-align-items-center;
      padding: 5px 0;
      text-align: center;
    }
    
    &:hover {
      .p1-gnav_itemTxt {
        &.-jp {
          @include breakpoint-pc {
            transform: translateY(0);
//            transform: translateY(150%);  //日本語→ホバー時英語の場合は、この行を有効にする
          }
        }
        &.-en {
          @include breakpoint-pc {
            transform: translate(-50%, 200%);
//            transform: translate(-50%, -50%);  //日本語→ホバー時英語の場合は、この行を有効にする
          }
        }
      }
    }
  }
  
  &_itemTxt {
    display: inline-block;
    color: inherit;

    &.-jp {
      display: block;
      margin-bottom: 10px;
      font-size: 2.2rem;
      line-height: 1;
      @include font-zomin("bd");

      @include breakpoint-pc {
        margin-bottom: 0;
        font-size: 1.5rem;
        transform: translateY(-200%);
//        transform: translateY(0);  //日本語→ホバー時英語の場合は、この行を有効にする
        @include single-transition(all, 0.2s, ease-out, 0s);
      }
      @include breakpoint-narrowinner {
        font-size: 1.6rem;
      }
    }

    &.-en {
      display: block;
      position: relative;
      font-size: 1.9rem;
      line-height: 1;
      @include font-critxt("r");

      @include breakpoint-pc {
//        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
//        transform: translate(-50%, -200%);  //日本語→ホバー時英語の場合は、この行を有効にする
        @include single-transition(all, 0.2s, ease-out, 0s);
      }
    }
  }
}


.l-header {
  &.js-isscroll {
    .p1-gnav {
      background: none;

      @include breakpoint-pc {
        border-style: none;
        border-radius: 0;
      }
      
      .p1-gnav_list.-btn {
        @include breakpoint-pc {
          margin-right: 10px;
        }
      }
    }
    
  }

  &.js-noscroll {
    .p1-gnav { 
      @include breakpoint-pc {
      }
    }
  }
}
