.p3-headline {
  ///// 見出しテキスト寄せ /////
  &.-left {
    text-align: left;
  }  
  &.-center {
    text-align: center;
  }  
  &.-right {
    text-align: right;
  }  
  &.-justify {
    text-align: justify;
  }
  
  ///// 見出しテキストカラー /////
  &.-wh {
    color: color-wh(1);
  }
  &.-bk {
    color: color-bk(1);
  }
  &.-white01 {
    color: color-white01(1);
  }
  &.-black01 {
    color: color-black01(1);
  }
  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-red01 {
    color: color-red01(1);
  }
  &.-red02 {
    color: color-red02(1);
  }

  ///// タイトルセット /////
  &.-ttlset01, &.-ttlset02 {
    position: relative;
    text-align: center;
    
    &:after {
      display: inline-block;
      position: relative;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &.-ttlset01 {
    &:after {
      width: 30px;
      height: 12px;
      margin: 30px auto 0;
      background-image: url("../images/common/icon/subttl_deco_gray.png");
    }
  }
  &.-ttlset02 {
    &:after {
      width: 58px;
      height: 42px;
      margin: 15px auto -15px auto; //ドロップシャドウ無しに比べて、上下に15pxずつ画像が大きくなっているので、上下marginを-ttlset01に比べて-15pxずつする
      background-image: url("../images/common/icon/subttl_deco_white_shadow.png");
    }
  }
  

  ///// タイトル /////
  &.-ttl01 {
    @include font-rigids();
    @include fontSize(6, 4.8);  //6 * 0.8 = 4.8
  }

  &.-ttl02 {
    @include font-zomin("bd");
    @include fontSize(1.5, 1.2);  //1.5 * 0.8 = 1.2
  }

  &.-ttl03 {
    @include font-zomin("bd");
    @include fontSize(3.6, 2.88);  //3.6 * 0.8 = 2.88
    
    &.-lh150 {
      line-height: 1.5;
    }
  }

  &.-ttl04 {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 20px;
    @include font-rigids();
//    @include fontSize(6, 4.8);  //6 * 0.8 = 4.8
    @include fontSize(6, 4.5);  //6 * 0.8 = 4.8
    
    @include breakpoint-tb {
      gap: 30px;
    }
    @include breakpoint-pc {
      gap: 45px;
    }

    &:before, &:after {
      display: block;
      position: relative;
      content: "";
      width: 100%;
      max-width: 200px;
      height: 1px;
      background-color: color-black01(1);
    }

    span {
      @include flex-shrink-0;
    }
  }

  &.-ttl05 {
    @include font-zomin("bk");
    @include fontSize(4.2, 3.36);  //4.2 * 0.8 = 3.36
    line-height: 1.3;
    
    &.-lh160 {
      line-height: 1.6;
    }
  }

  &.-ttl06 {
    @include font-zomin("r");
    @include fontSize(3.2, 2.56);  //3.2 * 0.8 = 2.56
    line-height: 1.3;
    
    &.-deco {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
    }

    &:before {
      @include flex-shrink-0;
      display: inline-block;
      position: relative;
      top: 6px;
      content: "";
      width: 7px;
      height: 17px;
      margin-right: 12px;
      background-image: url("../images/common/icon/subttl_deco_gray_v.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-pc {
        top: 8px;
        width: 9px;
        height: 21px;
        margin-right: 16px;
      }
    }

    &.-lh100 {
      line-height: 1;
    }

    &.-ls015 {
      letter-spacing: 0.15em;
    }
  }

  &.-ttl07 {
    @include font-zomin("bd");
    @include fontSize(2.2, 1.76);  //2.2 * 0.8 = 1.76
    line-height: 1.45;
  }

  &.-ttl08 {
    @include font-critxt("r");
    @include fontSize(5.6, 4.48);  //5.6 * 0.8 = 4.48
  }

  &.-ttl09 {
    @include font-alibsb("r");
    @include fontSize(3.2, 2.56);  //3.2 * 0.8 = 2.56
    line-height: 1.3;
    
    &.-deco {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
    }

    &:before {
      @include flex-shrink-0;
      display: inline-block;
      position: relative;
      top: 1px;
      content: "";
      width: 7px;
      height: 17px;
      margin-right: 12px;
      background-image: url("../images/common/icon/subttl_deco_gray_v.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-pc {
        width: 9px;
        height: 21px;
        margin-right: 16px;
      }
    }

    &.-lh100 {
      line-height: 1;
    }

    &.-ls019 {
      letter-spacing: 0.19em;
    }
  }

  &.-ttl10 {
    @include font-alibsb("r");
    @include fontSize(2.6, 2.08);  //2.6 * 0.8 = 2.08
    line-height: 1.3;

    &.-lh100 {
      line-height: 1;
    }

    &.-ls019 {
      letter-spacing: 0.19em;
    }
  }

  &.-ttl11 {
    @include font-oswald("m");
    @include fontSize(3.6, 2.88); //3.6 * 0.8 = 2.88
    line-height: 1.3;

    &.-lh100 {
      line-height: 1;
    }

    &.-ls006 {
      letter-spacing: 0.06em;
    }

    @include breakpoint-pc {
    }
  }

  &.-ttl12 {
    box-sizing: border-box;
    @include font-zomin("bd");
    @include fontSize(2, 1.6);  //2 * 0.8 = 1.6
    line-height: 1.3;

    &.-lh100 {
      line-height: 1;
    }

    &.-ls019 {
      letter-spacing: 0.19em;
    }
    
    &.-borderbtm {
      position: relative;
      overflow: visible;
      padding: 10px 0;
      border-bottom: 1px solid color-gray01(1);

      &:before {
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        z-index: 1;
        content: "";
        width: 160px;
        height: 3px;
        background-color: color-red01(1);
      }
    }
  }

  &.-ttl13 {
    @include font-zomin("bd");
    font-size: 2.857142857142857vw;  //20px / 700 * 100
    line-height: 2.1;
    letter-spacing: -0.05em;
    
    @media all and (min-width: 700px) {
      font-size: 2rem;
    }
    @include breakpoint-pc {
      font-size: 1.5rem;
//      font-size: 1.358024691358025vw;    //22px / 1620px * 100
      line-height: 2;
    }
    @include breakpoint-w900 {
      font-size: 1.8rem;
//      font-size: 1.358024691358025vw;    //22px / 1620px * 100
      line-height: 2.1;
    }
    @include breakpoint-narrow {
      font-size: 2rem;
      line-height: 2.2;
    }
    @include breakpoint-max {
      font-size: 2.2rem;
    }
  }
}