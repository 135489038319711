@mixin btnsize($pc, $sp) {
  width: 100%;
  @include breakpoint-sp {
    max-width: $sp;
  }
  @include breakpoint-pc {
    width: $pc;
  }
}

@mixin btn($status) {
  clear: both;
  text-align: center;

  &_inner, a {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    @include font-ntsan("r");
    @include fontSize(1.7, 1.36); //1.7 * 0.8 = 1.36
    line-height: 1;
    cursor: pointer;

    @include breakpoint-pc {
    }
  }

  @if $status == 1 {
    &_inner, a {
      padding: 18px 0 19px;
      @include font-ntsan("r");
      @include fontSize(1.7, 1.36); //1.7 * 0.8 = 1.36
      letter-spacing: 0.05em;
      color: color-wh(1);
      @include single-transition();
      
      @include breakpoint-pc {
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
    
    &_txt, &_icon {
      position: relative;
      z-index: 1;
    }
    &_txt {
      display: inline-block;
      transform: translateY(-2px);
      padding: 0 15px;
      box-sizing: border-box;
    }
  }

  @else if $status == 2 {
    &_inner, a {
      padding: 13px 0 14px;
      @include font-ntsan("m");
      @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.36
      letter-spacing: 0.05em;
      color: color-black01(1);
      border-bottom: 1px solid color-black01(1);
      
      &:hover {
        @extend %hover;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
    
    &_txt, &_icon {
      position: relative;
      z-index: 1;
    }
    &_txt {
      display: inline-block;
      padding: 0 15px;
      box-sizing: border-box;
    }
    &_icon {
      transform: scale(-1, 1);
    }
  }

  @else if $status == 3 {
    &_inner, a {
//      padding: 13px 0 14px;
      @include font-oswald("m");
      @include fontSize(1.9, 1.52); //1.9 * 0.8 = 1.52
      letter-spacing: 0.05em;
      color: color-black01(1);
      
      &:hover {
        @extend %hover;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }

    &_txt {
      @include flexbox;
      @include flex-direction-column;
      @include flex-wrap-nowrap;
      @include flex-justify-center;
      @include flex-align-items-center;
      position: relative;
      z-index: 1;
//      padding: 0 15px;
      box-sizing: border-box;
      
      &:before {
        display: block;
        position: relative;
        content: "";
        width: 67px;
        height: 42px;
        background-image: url("../images/common/icon/backtolist.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  @else if $status == 4 {
    &_inner, a {
      padding: 11px 0 11px;
      @include font-alibsb("r");
      @include fontSize(2.2, 1.76); //2.2 * 0.8 = 1.76
      letter-spacing: 0.06em;
      color: color-wh(1);
      @include single-transition();
      
      @include breakpoint-pc {
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
    
    &_txt, &_icon {
      position: relative;
      z-index: 1;
    }
    &_txt {
      display: inline-block;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
}


.p0-btn1 {
  position: relative;
  z-index: 0;
  width: 280px;
  @include btn(1);
//  border-radius: 100vh;
  box-sizing: border-box;
//  background-color: color-black01(1);
//  border: 2px solid color-red03(1);
  // Transition out for border
//	@include single-transition(all, 0.2s, ease-out, 0s);

  @include breakpoint-pc {

  }

	&:hover, &:focus{
    // Transition in for border
//    border: 2px solid color-red04(1);
//    @include single-transition(all, 0.3s, ease-out, 0.1s);
  }

  p {
    margin-bottom: 0 !important;
  }
}

.p0-btn2 {
  position: relative;
  width: 180px;
  @include btn(2);
  box-sizing: border-box;

  p {
    margin-bottom: 0 !important;
  }
}

.p0-btn3 {
  position: relative;
  width: 140px;
  @include btn(3);
  box-sizing: border-box;

  p {
    margin-bottom: 0 !important;
  }
}

.p0-btn4 {
  position: relative;
  overflow: hidden;
  width: 240px;
  @include btn(4);
  border: 1px solid color-red01(1);
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: color-black01(1);

  @include breakpoint-pc {

  }

  p {
    margin-bottom: 0 !important;
  }
}


///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
  }
  
  &.-left {
    @include flex-justify-flex-start;
  }
  
  &.-center {
    @include flex-justify-center;
    @include flex-align-items-center;
    
    @include breakpoint-pc {
      @include flex-align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
  }
  
  &_item {
    margin-bottom: 20px;
    
    @include breakpoint-pc {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    @include breakpoint-content {
      margin-right: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0;

      @include breakpoint-pc {
        margin-right: 0;
      }
      @include breakpoint-content {
        margin-right: 0;
      }
    }
  }
}