///// fadein effect /////
///// フェードインさせたいアイテムにjs-fadeinitemクラスを付ける /////
.js-fadeinitem {
  opacity: 0;
}
///// フェードインさせたい時に、js-fadeinクラスが追加される /////
.js-fadein {
  animation: 0.5s ease-in-out 0s 1 both fadeIn;
}

///// フェードイン&ダウンさせたいアイテムにjs-fadeindownitemクラスを付ける /////
.js-fadeindownitem {
  opacity: 0;
  transform: translateY(-10px);
}
///// フェードイン&ダウンさせたい時に、js-fadeindownクラスが追加される /////
.js-fadeindown {
  animation: 0.5s ease-in-out 0s 1 both fadeInDown;
}

///// toppage kv anime setting /////
$kv_duration1: 0.3s;      //1コマ
$kv_duration2: $kv_duration1 * 2;  //2コマにまたがる
$kv_duration3: $kv_duration1 * 3;  //3コマにまたがる
$kv_duration4: $kv_duration1 * 4;  //4コマにまたがる

$kv_interval: 0.15s;  //コマ間のインターバル

$kv_delay_base: 0.3s;
$kv_delay1: $kv_delay_base + 0.75s;
$kv_delay2: $kv_delay1 + $kv_interval + $kv_duration1;
$kv_delay3: $kv_delay2 + $kv_interval + $kv_duration1;
$kv_delay4: $kv_delay3 + $kv_interval + $kv_duration1;
$kv_delay5: $kv_delay4 + $kv_interval + $kv_duration1;
$kv_delay6: $kv_delay5 + $kv_interval + $kv_duration1;

//スプーンの動き用
$kv_delay1_1: $kv_delay1;
$kv_delay1_2: $kv_delay1_1 + ($kv_duration1 * 0.5);
$kv_delay1_3: $kv_delay1_2 + ($kv_duration1 * 0.5);


///// move effect /////
.u-kvmoveleft45 {
  opacity: 0;
  animation: $kv_duration2 linear $kv_delay4 1 both kvMoveLeft10;
}
.u-kvmovedown234 {
  opacity: 0;
  animation: $kv_duration3 linear $kv_delay2 1 both kvMoveDown10;
}
.u-kvmoveright345 {
  opacity: 0;
  animation: $kv_duration3 linear $kv_delay3 1 both kvMoveRight10;
}
.u-kvmoveright56 {
  opacity: 0;
  animation: $kv_duration2 linear $kv_delay5 1 both kvMoveRight10;
}
.u-kvzoomin1 {
  opacity: 0;
  animation: $kv_duration2 linear $kv_delay1 1 both kvZoomIn10;
}
.u-kvfadein23 {
  opacity: 0;
  animation: $kv_duration2 linear $kv_delay2 1 both kvFadeIn;
}
.u-kvfadein2 {
  opacity: 0;
  animation: $kv_duration1 linear $kv_delay2 1 both kvFadeIn;
}
.u-kvupdown1_1 {
  opacity: 0;
  animation: $kv_duration1 ease-in-out $kv_delay1_1 1 both kvDownFadeIn;
}
.u-kvupdown1_2 {
  opacity: 0;
  animation: $kv_duration1 ease-in-out $kv_delay1_2 1 both kvDownFadeIn;
}
.u-kvupdown1_3 {
  opacity: 0;
  animation: $kv_duration1 ease-in-out $kv_delay1_3 1 both kvDownFadeIn;
}

///// グルグル動かす /////
.u-moveCloud1 {
  -webkit-animation: FloatHorizontal-01 12s ease-in-out infinite alternate;
  animation: FloatHorizontal-01 12s ease-in-out infinite alternate;
  
  img {
    -webkit-animation: FloatVertical-01 10s ease-in-out infinite alternate;
    animation: FloatVertical-01 10s ease-in-out infinite alternate;
  }
}
.u-moveCloud1-delay {
  -webkit-animation: FloatHorizontal-01 12s ease-in-out -3s infinite alternate;
  animation: FloatHorizontal-01 12s ease-in-out -3s infinite alternate;
  
  img {
    -webkit-animation: FloatVertical-01 10s ease-in-out -3s infinite alternate;
    animation: FloatVertical-01 10s ease-in-out -3s infinite alternate;
  }
}



//// ドロワーイン /////
@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    background: color-black01(0);
  }
  1% {
    background: color-black01(0.95);
  }
  100% {
    background: color-black01(0.95);
    transform: translateY(0);
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-black01(0.95);
  }
  99% {
    background: color-black01(0.95);
  }
  100% {
    background: color-black01(0);
    transform: translateY(-100vh);
  }
}


///// フェードイン・アウト /////
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

///// フェードインダウン /////
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

///// KVアニメーション /////
@keyframes kvMoveLeft10 {
  0% {
    opacity: 0;
    transform: translate(10px, 0);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes kvMoveDown10 {
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes kvMoveDown20 {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes kvMoveRight10 {
  0% {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes kvMoveRight20 {
  0% {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes kvZoomIn10 {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes kvFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes kvDownFadeIn {
  0% {
    opacity: 0;
    transform: translate(0px, -15px);
    animation-timing-function: linear;
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
    animation-timing-function: linear;
  }
}
@keyframes kvUpDown {
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: linear;
  }
  21.4286% {
    transform: translate(0px, -15px);
    animation-timing-function: ease-in-out;
  }
  28.5714% {
    transform: translate(0px, -15px);
    animation-timing-function: ease-in-out;
  }
  71.4286% {
    transform: translate(0px, 15px);
    animation-timing-function: ease-in-out;
  }
  78.5714% {
    transform: translate(0px, 15px);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translate(0px, 0px);
    animation-timing-function: linear;
  }
}

///// グルグル動かす /////
@-webkit-keyframes FloatHorizontal-01 {
  0% {
    transform: translate3d(10px, 0, 0);
  }
  50% {
    transform: translate3d(-10px, 0, 0);
  }
  100% {
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes FloatHorizontal-01 {
  0% {
    transform: translate3d(10px, 0, 0);
  }
  50% {
    transform: translate3d(-10px, 0, 0);
  }
  100% {
    transform: translate3d(10px, 0, 0);
  }
}

@-webkit-keyframes FloatVertical-01 {
  0% {
    transform: translate3d(0, 10px, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
  }
}
@keyframes FloatVertical-01 {
  0% {
    transform: translate3d(0, 10px, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
  }
}



// Sweep button styles
.sweepButton {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: color-black01(1);
  border: 2px solid color-red04(0.4);
  // Transition out for border
  @include single-transition(all, 0.2s, ease-out, 0s);

	.gradient {
		display: block;
		position: absolute;
    top: 0;
		right: 0;
    width: 300%;
    height: 100%;
		bottom: auto;
		margin: auto;
		z-index: 0;
		@include sweepButtonGradient();
    // Transition out for gradient
    @include single-transition(all, 0s, ease-out, 0s);
    @include transform(translateX(-35%));

	}

	&:hover, &:focus{
    // Transition in for border
    border: 2px solid color-red04(1);
    @include single-transition(all, 0.3s, ease-out, 0.1s);

		.gradient {
			@include transform(translateX(100%));
      // Transition in for gradient
      @include single-transition(all, 0.8s, ease-out, 0s);
		}
	}
}