.p0-decoration {
  display: block;

  & img {
    display: block;
  }

  ///// 区切り装飾 /////
  &.-separater01 {
    img {
      width: 30px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ///// テキストにかかった、太いアンダーラインを引く /////
  &.-underline01 {
    display: inline;
    background: transparent;
    background: -moz-linear-gradient( transparent 0%, transparent 50%, color-base16(0.5) 50%, color-base16(0.5) 95%, transparent 95%);
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), color-stop(50%, color-base16(0.5)), color-stop(95%, color-base16(0.5)), to(transparent));
    background: -webkit-linear-gradient( transparent 0%, transparent 50%, color-base16(0.5) 50%, color-base16(0.5) 95%, transparent 95%);
    background: -o-linear-gradient( transparent 0%, transparent 50%, color-base16(0.5) 50%, color-base16(0.5) 95%, transparent 95%);
    background: -ms-linear-gradient( transparent 0%, transparent 50%, color-base16(0.5) 50%, color-base16(0.5) 95%, transparent 95%);
    background: linear-gradient( transparent 0%, transparent 50%, color-base16(0.5) 50%, color-base16(0.5) 95%, transparent 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=transparent, endColorstr=transparent,GradientType=0 );
  }
}