@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre&family=Crimson+Text:ital@0;1&family=Noto+Sans+JP:wght@400;500;700&family=Oswald:wght@400;500&family=Shippori+Mincho&family=Zen+Old+Mincho:wght@400;700;900&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


//Rigid Square
@font-face {
  font-family: 'rigid_squarebold';
  src: url('/assets/font/rigit-square-webfont.eot');
  src: url('/assets/font/rigit-square-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/font/rigit-square-webfont.woff2') format('woff2'),
       url('/assets/font/rigit-square-webfont.woff') format('woff'),
       url('/assets/font/rigit-square-webfont.ttf') format('truetype'),
       url('/assets/font/rigit-square-webfont.svg#rigid_squarebold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@mixin font-rigids() {
  font-family: 'rigid_squarebold';
  font-weight: normal;
  font-style: normal;
}


///// Google Fonts /////
//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'bd' {
    font-weight: 700;
  }
}

//Zen Old Mincho
@mixin font-zomin($status) {
  font-family: 'Zen Old Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'bd' {
    font-weight: 700;
  } @else if $status == 'bk' {
    font-weight: 900;
  }
}

//Abhaya Libre SemiBold
@mixin font-alibsb($status) {
  font-family: 'Abhaya Libre', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  }
}

//Crimson Text
@mixin font-critxt($status) {
  font-family: 'Crimson Text', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'ri' {
    font-weight: 400;
    font-style: italic;
  }
}

//Oswald
@mixin font-oswald($status) {
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  }
}

//Shippori Mincho / しっぽり明朝
@mixin font-sprmin($status) {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  }
}



/////複数フォントを利用 /////
//Crimson Text > Shippori Mincho
@mixin font-critxtandsprmin($status) {
  font-family: 'Crimson Text', 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  }
}

///// Google Fonts /////
