.p4-postarea {
  h4 {
    font-size: 2rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  
  .aligncenter{
  	display: block;
  	margin-left: auto;
  	margin-right: auto;
  }
  .alignright{
  	float: right;
  	margin-left: 1.5em;
  }
  .alignleft{
  	float: left;
  	margin-right: 1.5em;
  }
  .alignnone {
    float: none;
  }

  & img {
    max-width: 100%;
    height: auto;
    
    @include breakpoint-pc {
    }

    &.vertically-L {
    }
    &.vertically-S {
      @media all and (min-width:650px) and (max-width:799px) {
        width: inherit;
      }
      @include breakpoint-pc {
        width: inherit;
      }
    }
    &.horizontally-L {
    }
    &.horizontally-S {
      @media all and (min-width:650px) and (max-width:799px) {
        width: inherit;
      }
      @include breakpoint-pc {
        width: inherit;
      }
    }
  }

  & p {
    margin-bottom: 0;
  }

  p + .p3-text.-articlettl01 {
    margin-top: 10px;

    @include breakpoint-pc {
      margin-top: 20px;
    }
  }
  p + .p3-text.-articlettl02 {
    margin-top: 5px;
  
    @include breakpoint-pc {
      margin-top: 10px;
    }
  }

  a {
    text-decoration: underline;
    
    &:hover {
      opacity: 0.7;
    }
  }
  
  &.p0-products_article {
    a {
      text-decoration: none;
      
      &:hover {
        opacity: 1;
      }
    }
  }
}

