.p2-ftnav {
  margin: 0 auto;
  @include font-ntsan("m");
  @include fontSize(1.3, 1.04); //1.3 * 0.8 = 1.04
  line-height: 1;
  color: color-wh(1);

  &_inner {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
  }

  &_item {
    border-right: 1px solid color-wh(1);
    box-sizing: border-box;
    
    &:last-of-type {
      border-right-style: none;
    }

    a {
      display: block;
      padding: 1px 13px;
      box-sizing: border-box;
      color: color-wh(1);
//      @extend %hover;

      @include breakpoint-tb {
        padding: 1px 18px;
      }
      @include breakpoint-pc {
        padding: 1px 23px;
      }
      
      span {
        display: inline-block;
        box-sizing: border-box;
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 0.15s linear;
      }

      &:hover {
        span {
          border-bottom-color: color-wh(1);
        }
//        text-decoration: underline;
      }
    }
  }
}