.p0-slidelure {
  .swiper-wrapper{
    transition-timing-function: linear;
    
    img {
      pointer-events: none;
    }
  }

  ::selection {
    background: transparent;
  }
  ::-moz-selection {
    background: transparent;
  }
}