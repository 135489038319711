.p0-tbl {
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-pc {
  }

  &.-type01 {
    display: block;
    
    .p0-tbl_item {
      padding: 10px 0 15px;
      border-bottom: 1px solid color-gray02(1);
      box-sizing: border-box;

      @include breakpoint-pc {
        padding: 20px 0 25px;
      }
    }
  }
}